import get from 'lodash/get';
import { merge } from 'lodash/fp';
import { ACTION_TYPES } from '@marketmuse/data-state';

import initialState from '../config/initialState';

const filterReducer = (state = initialState.filter, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER_SET_IDENTITY:
      return { ...state, site: { ...action.payload.site } };

    case ACTION_TYPES.ACTIVE_INVENTORY_SET:
      return { ...state, site: { ...action.site } };

    case ACTION_TYPES.INVENTORY_VERSIONS_SAVE:
      return { ...state, versions: [...action.payload] };

    case ACTION_TYPES.SIGN_OUT:
      return {
        ...state,
        site: initialState.filter.site,
        versions: initialState.filter.versions,
      };
    case ACTION_TYPES.INVENTORY_FIELDS_UPDATE:
      if (get(state, 'site.id') !== action.payload.siteId) {
        return state;
      }

      return {
        ...state,
        site: merge(state.site, action.payload.updatedFields),
      };

    default:
      return state;
  }
};

export default filterReducer;
