import React from 'react';
import orgRoles from '../../config/orgRoles';
import hideTooltip from '../../utils/hideTooltip';
import Popper from '../../components/Tooltip/Popper';
import {
  SimpleTextInputLabel,
  SimpleTextInputRequiredStar,
} from '../../components/SimpleTextInput';

const SimpleOrgRole = ({ onDebounce, targetValue }) => {
  const items = [orgRoles.ADMIN, orgRoles.MM_CX_ADMIN, orgRoles.USER].map(
    role => ({
      id: role.id,
      title: role.title,
      onClick: () => {
        hideTooltip('add-member-role-dropdown');
        onDebounce &&
          typeof onDebounce === 'function' &&
          onDebounce(role.id, 'role');
      },
    }),
  );

  return (
    <>
      <div>
        <SimpleTextInputLabel>
          <SimpleTextInputRequiredStar>*</SimpleTextInputRequiredStar>
          <span>Organization Role</span>
        </SimpleTextInputLabel>
      </div>
      <Popper
        required
        tippyUsage="dropdown"
        tippyTheme="light"
        handleId="add-member-role-dropdown"
        items={items}
        selection={orgRoles[targetValue] && orgRoles[targetValue].title}
      />
    </>
  );
};

export default SimpleOrgRole;
