import { Dialog } from '@headlessui/react';
import { ReactNode, FC } from 'react';

export enum MODAL_MODE {
  slideoutRight = 'slideout-right',
  slideoutLeft = 'slideout-left',
  takeover = 'takeover',
  complete = 'complete',
}

interface PanelClassNames {
  panelWrapperClassName?: string | Array<string>;
  panelClassName?: string | Array<string>;
}

export interface ModalProps extends PanelClassNames {
  children: ReactNode;
  className?: string;
  closeBtnClassName?: string | Array<string>;
  disableClose?: boolean;
  isVisible: boolean;
  mode?: keyof typeof MODAL_MODE;
  onAfterLeave?: () => void;
  onClose: () => void;
  onOutsideClick?: () => void;
  unmount?: boolean;
}

export interface ModalContentProps extends PanelClassNames {
  children: ReactNode;
  mode?: keyof typeof MODAL_MODE;
  onClose: () => void;
}

export type ModalPropsInterface = FC<React.PropsWithChildren<ModalProps>> & {
  Title: typeof Dialog.Title;
  Description: typeof Dialog.Description;
};
