import { isEmpty, trim } from 'lodash';
import { InputPillListProps } from './types';

export const serializeInputValue = ({
  validation,
  inputValue,
}: {
  inputValue: string;
  validation?: InputPillListProps['validation'];
}) => {
  const defaultValid = { valid: true, reason: '' };
  if (isEmpty(inputValue)) {
    return {
      items: [],
      inputStatus: defaultValid,
    };
  }

  const values = inputValue
    .split(',')
    .map(value => trim(value))
    .filter(value => !isEmpty(value));

  let inputStatus = defaultValid;
  values.forEach(value => {
    // If theres many items, and one was invalid, keep that state
    if (!inputStatus.valid || !validation) {
      return;
    }

    // Assume valid if no validation function
    inputStatus = validation(value);
  });

  return {
    items: values,
    inputStatus: inputStatus,
  };
};
