import { IEditorFeatures } from '../types';
import * as CKE_TOOLBAR_TYPES from './toolbarTypes';

const CKE_TOOLBAR_ITEMS = CKE_TOOLBAR_TYPES.CKE_TOOLBAR_ITEMS;

// by visual order
export const getToolbarItems = (toolbar?: IEditorFeatures['toolbar']) => {
  return [
    CKE_TOOLBAR_ITEMS.HEADING,
    CKE_TOOLBAR_ITEMS.DIVIDER,

    CKE_TOOLBAR_ITEMS.BOLD,
    CKE_TOOLBAR_ITEMS.ITALIC,
    CKE_TOOLBAR_ITEMS.UNDERLINE,
    CKE_TOOLBAR_ITEMS.LINK,
    CKE_TOOLBAR_ITEMS.DIVIDER,
    {
      label: 'List',
      icon: '<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 3C1.16875 3 0.5 3.66875 0.5 4.5V7.5C0.5 8.33125 1.16875 9 2 9H5C5.83125 9 6.5 8.33125 6.5 7.5V4.5C6.5 3.66875 5.83125 3 5 3H2ZM11.5 4C10.3937 4 9.5 4.89375 9.5 6C9.5 7.10625 10.3937 8 11.5 8H29.5C30.6063 8 31.5 7.10625 31.5 6C31.5 4.89375 30.6063 4 29.5 4H11.5ZM11.5 14C10.3937 14 9.5 14.8937 9.5 16C9.5 17.1063 10.3937 18 11.5 18H29.5C30.6063 18 31.5 17.1063 31.5 16C31.5 14.8937 30.6063 14 29.5 14H11.5ZM11.5 24C10.3937 24 9.5 24.8937 9.5 26C9.5 27.1063 10.3937 28 11.5 28H29.5C30.6063 28 31.5 27.1063 31.5 26C31.5 24.8937 30.6063 24 29.5 24H11.5ZM0.5 14.5V17.5C0.5 18.3313 1.16875 19 2 19H5C5.83125 19 6.5 18.3313 6.5 17.5V14.5C6.5 13.6687 5.83125 13 5 13H2C1.16875 13 0.5 13.6687 0.5 14.5ZM2 23C1.16875 23 0.5 23.6687 0.5 24.5V27.5C0.5 28.3313 1.16875 29 2 29H5C5.83125 29 6.5 28.3313 6.5 27.5V24.5C6.5 23.6687 5.83125 23 5 23H2Z" fill="#8f8b8f"/></svg>',
      items: [
        CKE_TOOLBAR_ITEMS.BULLETED_LIST,
        CKE_TOOLBAR_ITEMS.NUMBERED_LIST,
        CKE_TOOLBAR_ITEMS.TODO_LIST,
      ],
    },
    CKE_TOOLBAR_ITEMS.DIVIDER,
    CKE_TOOLBAR_ITEMS.INDENT,
    CKE_TOOLBAR_ITEMS.OUTDENT,
    CKE_TOOLBAR_ITEMS.DIVIDER,
    CKE_TOOLBAR_ITEMS.REDO,
    CKE_TOOLBAR_ITEMS.UNDO,
    CKE_TOOLBAR_ITEMS.DIVIDER,
    CKE_TOOLBAR_ITEMS.BLOCK_QUOTE,
    CKE_TOOLBAR_ITEMS.STRIKETHROUGH,
    CKE_TOOLBAR_ITEMS.SUBSCRIPT,
    CKE_TOOLBAR_ITEMS.SUPERSCRIPT,
    CKE_TOOLBAR_ITEMS.TABLE,
    CKE_TOOLBAR_ITEMS.IMAGE_INSERT,
    CKE_TOOLBAR_ITEMS.EXPORT,
    ...(toolbar ? [...toolbar] : []),
  ];
};

// enabling FONT_BACKGROUND_COLOR will overwrite Editor's term highlighter color
export const removePlugins = ['FontBackgroundColor', 'FontColor'];

export const heading = {
  options: [
    {
      model: 'paragraph',
      title: 'Paragraph',
      class: 'ck-heading_paragraph',
    },
    {
      model: 'heading1',
      view: 'h1',
      title: 'Heading 1',
      class: 'ck-heading_heading1',
    },
    {
      model: 'heading2',
      view: 'h2',
      title: 'Heading 2',
      class: 'ck-heading_heading2',
    },
    {
      model: 'heading3',
      view: 'h3',
      title: 'Heading 3',
      class: 'ck-heading_heading3',
    },
    {
      model: 'heading4',
      view: 'h4',
      title: 'Heading 4',
      class: 'ck-heading_heading4',
    },
    {
      model: 'heading5',
      view: 'h5',
      title: 'Heading 5',
      class: 'ck-heading_heading5',
    },
    {
      model: 'heading6',
      view: 'h6',
      title: 'Heading 6',
      class: 'ck-heading_heading6',
    },
  ],
};

export const image = {
  styles: ['alignCenter', 'alignLeft', 'alignRight'],
  resizeOptions: [
    {
      name: 'resizeImage:original',
      label: 'Original',
      value: null,
    },
    {
      name: 'resizeImage:50',
      label: '50%',
      value: '50',
    },
    {
      name: 'resizeImage:75',
      label: '75%',
      value: '75',
    },
  ],
  toolbar: [
    'imageTextAlternative',
    'toggleImageCaption',
    '|',
    'imageStyle:inline',
    'imageStyle:wrapText',
    'imageStyle:breakText',
    'imageStyle:block',
    'imageStyle:side',
    '|',
    'resizeImage',
    '|',
    'linkImage',
  ],
  insert: {
    integrations: ['url'],
  },
};

export const table = {
  contentToolbar: [
    'tableColumn',
    'tableRow',
    'mergeTableCells',
    'tableProperties',
    'tableCellProperties',
    'toggleTableCaption',
  ],
  defaultHeadings: { rows: 1, columns: 1 },
};

export const link = {
  decorators: {
    openInNewTab: {
      mode: 'manual',
      label: 'Open in a new tab',
      attributes: {
        target: '_blank',
      },
    },
  },
};

export const indentBlock = {
  classes: [
    'editor-block-indent-xs',
    'editor-block-indent-sm',
    'editor-block-indent-md',
    'editor-block-indent-lg',
    'editor-block-indent-xl',
  ],
};
