import { SliceTypeSavedViews } from '../types';

export const tableUiConfig = {
  default: {
    page: 1,
    limit: 20,
    query: '',
  },
};

export const initialState: SliceTypeSavedViews = {
  PAGE_TOPICS: { current: {}, list: [], tableUiConfig },
  PAGES: { current: {}, list: [], tableUiConfig },
  TOPICS: { current: {}, list: [], tableUiConfig },
};
