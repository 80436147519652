/**
 * Super class extended by event classes. Note that event classes
 * are singletons - ie. the file they're implemented in should
 * default export an instance of the class.
 */

import { isNil } from 'lodash';

import { tracker } from './utils/initTracker';
import { getDefaults } from './utils/getDefaults';

class Event {
  // global record method
  record(action, data) {
    // generate an id to uniquely identify every event
    const eventId = Math.round(Math.random() * 100000000);

    // events should pass their action id
    if (isNil(action)) {
      throw new Error('Action parameter missing');
    }

    // if tracker not ready - ie. all agents are not initialized
    // log error and stop recording.
    if (!tracker) {
      console.warn('Failed to record event: tracker not ready');
      return;
    }

    // if `data` is not provided, it is likely that an there is
    // no implementation of the `record` method in an event class,
    // so this method was invoked directly. In that case, fail.
    if (!data || typeof data !== 'object') {
      console.warn(
        'Record attempt failed: ' +
          'No tracking data provided. It is most likely ' +
          'that an event class is missing implementation of the ' +
          'the `record` method.',
      );
      return;
    }

    // gather final track data
    const finalData = {
      action,
      eventId,
      ...getDefaults(),
      ...data,
      category: data.category || 'General',
    };

    // log
    console.log('🚨 Tracking:', finalData);

    // track event
    tracker.track(finalData);

    // track aggregate event
    tracker.track({ action: `Aggregate${finalData.category}` });
  }
}

export default Event;
