import React, { useRef, memo, forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';

import normalizeHotKey from '../../../hooks/useHotKeys/normalizeHotKey';
import * as propTypes from '../../../config/propTypes/simpleMenu';

import IconSvg from '../../IconSvg';
import Tooltip from '../../Tooltip/Tooltip';

import MenuItem from '../styles/MenuItem';
import MenuItemLeft from '../styles/MenuItemLeft';
import MenuItemRight from '../styles/MenuItemRight';
import MenuItemShortcut from '../styles/MenuItemShortcut';
import MenuItemText from '../styles/MenuItemText';

import SimpleMenuList from './SimpleMenuList';

const SimpleMenuItem = forwardRef(
  (
    {
      id,
      items,
      onClick,
      engagedHover,
      onMouseEnter,
      onMouseLeave,
      itemLeft,
      itemRight,
      title,
      nowrap,
      hotkey,
      ...props
    },
    ref,
  ) => {
    const hasItems = items && !isEmpty(items);
    const handleId = `data-mms--menu-item-${id}`;
    const defaultRef = useRef();
    const reference = ref ? ref : defaultRef;

    return (
      <>
        <MenuItem
          onClick={onClick}
          ref={reference}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          id={handleId}
          {...(onClick ? { 'data-mms--menu-item': id } : {})}
          {...props}
        >
          {itemLeft && <MenuItemLeft>{itemLeft}</MenuItemLeft>}

          <MenuItemText nowrap={nowrap}>{title}</MenuItemText>

          {!hasItems && hotkey && (
            <MenuItemRight>
              <MenuItemShortcut>{normalizeHotKey(hotkey)}</MenuItemShortcut>
            </MenuItemRight>
          )}

          {!hasItems && itemRight && <MenuItemRight>{itemRight}</MenuItemRight>}

          {hasItems && (
            <MenuItemRight>
              <IconSvg name="chevron-right" size="12" />
            </MenuItemRight>
          )}
        </MenuItem>
        <Tooltip
          reference={reference}
          html={
            <SimpleMenuList
              handleId={handleId}
              items={items}
              aria-label={`${title} menu`}
            />
          }
          disabled={!hasItems}
          interactive
          interactiveBorder={10}
          tippyUsage={'dropdown'}
          placement={'right-start'}
          offset={[-12, 0]}
        />
      </>
    );
  },
);

SimpleMenuItem.displayName = 'SimpleMenuItem';
SimpleMenuItem.propTypes = propTypes.item;

export default memo(SimpleMenuItem);
