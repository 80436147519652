import { get } from 'lodash';
import { titleCase } from '@marketmuse/utilities';

export const userDataLayer = ({
  user,
  site,
  subscriptions = [],
  recurlyAccount = {},
}) => {
  return {
    id: get(user, 'id'),
    fullName: get(user, 'fullName'),
    firstName: get(user, 'firstName'),
    lastName: get(user, 'lastName'),
    email: get(user, 'email'),
    org: get(user, 'org'),
    tier: get(user, 'org.clientTier')
      ? titleCase(get(user, 'org.clientTier'))
      : null,
    sfdcContactId: get(user, 'sfdcContactId'),
    site,
    subscriptions,
    address: {
      account: {
        firstName: get(recurlyAccount, 'firstName'),
        lastName: get(recurlyAccount, 'lastName'),
        ...(get(recurlyAccount, 'address') || {}),
      },
      billing: {
        firstName: get(recurlyAccount, 'billingInfo.firstName'),
        lastName: get(recurlyAccount, 'billingInfo.lastName'),
        ...(get(recurlyAccount, 'billingInfo.address') || {}),
      },
      shipping: get(recurlyAccount, 'shippingAddresses.0') || {},
    },
  };
};
