import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import Button from '../../../Button';
import Icon from '../../../Icon';
import Label from '../../components/Label';
import ErrorLabel from '../../components/ErrorLabel';

import {
  SideItemsLeft,
  SideItemsRight,
  SideItemLeft,
  SideItemRight,
} from '../../components/SideItems';

/**
 * IMPORTANT!
 * These are global styles that applies to EVERY wrapper of every theme.
 *
 * Please avoid making changes as much as possible.
 * If you can achieve it by creating a new theme, please do so.
 * If you HAVE TO make a chance, please check EVERY story
 * on storybook to make sure everything renders correctly!
 */
const Wrapper = styled.div`
  position: relative;
  display: flex; /* <- please never override this */
  align-items: center;
  border-radius: ${p => p.theme.borderRadius};
  height: ${p => p.theme.input.minHeight}px;
  ${p => p.textarea && `height: 80px; `}
`;

/**
 * IMPORTANT!
 * If you want to add a new component (ie. a button, label etc.) to the wrapper,
 * you can do so on a theme file. Below component renders on every theme, please
 * avoid modifying the base wrapper.
 */
const BaseWrapper = ({ children, clear, ...props } = {}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const processItemsRight = items => {
    if (typeof items === 'function') {
      return items({ clear }) || [];
    }
    return items || [];
  };

  const itemsRight = processItemsRight(props.itemsRight);

  const kind =
    props.type !== 'password'
      ? props.type
      : passwordVisible
      ? 'text'
      : 'password';

  if (props.type === 'password') {
    itemsRight.unshift(
      <Button
        default
        iconOnly
        className="mms--password-visibility-toggle"
        style={{ marginRight: -8, backgroundColor: 'transparent' }}
        onClick={() => setPasswordVisible(!passwordVisible)}
        icon={
          <Icon
            name="insights"
            size="28"
            style={{ fontSize: '12pt' }}
            color={passwordVisible ? 'blue' : null}
          />
        }
      />,
    );
  }

  return (
    <>
      {/* outer label */}
      {props.hasLabel && <Label required={props.required}>{props.label}</Label>}

      {/* wrapper */}
      <Wrapper
        className={props.className}
        style={props.style}
        textarea={props.textarea}
      >
        {/* items on the left */}
        {!isNil(props.itemsLeft) && (
          <SideItemsLeft>
            {props.itemsLeft.map((item, index) => (
              <SideItemLeft gap={props.gap} key={index.toString()}>
                {item}
              </SideItemLeft>
            ))}
          </SideItemsLeft>
        )}

        {/* pass locally managed vars to input components in arguments */}
        {children({ kind })}

        {/* items on the right */}
        {itemsRight.length > 0 && (
          <SideItemsRight gap={props.gap}>
            {itemsRight.map((item, index) => (
              <SideItemRight gap={props.gap} key={index.toString()}>
                {item}
              </SideItemRight>
            ))}
          </SideItemsRight>
        )}
      </Wrapper>

      {/* error label */}
      {!isNil(props.error) && props.error !== false && (
        <ErrorLabel>{props.error}</ErrorLabel>
      )}
    </>
  );
};

BaseWrapper.propTypes = {
  children: PropTypes.func,
  clear: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.any,
};

export default BaseWrapper;
