import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import { statBandMentions, regexCleanup } from '@marketmuse/utilities';

import Distribution from '../../../components/Distribution';
import IconSvg from '../../../components/IconSvg/IconSvg';

import { BarContentScore, BarWordCount } from '../Optimize/shared/Bars';

const Notification = styled.div`
  border-top: 1px solid ${p => p.theme.colors.grey15};
  padding: 12px;
  margin-bottom: 12px;
`;

const NotifHeader = styled.div`
  display: flex;
  align-items: center;
`;

const NotifIcon = styled.div`
  flex-shrink: 0;
  width: 32px;
`;

const NotifText = styled.div`
  flex-grow: 1;
  font-size: 13px;
  line-height: 16px;
  margin-left: 8px;
`;

const NotifRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 12px;
  background-color: ${p => p.theme.mmxGreyFA};
  border-radius: 4px;
  margin-top: 12px;
`;

const renderDist = ({ key, matches, term, terms }) => {
  const currentDistribution = get(matches, `['${regexCleanup(term)}']`);
  if (!currentDistribution) return null;

  const relatedTopicIndex = terms
    .map(rt => regexCleanup(rt.term))
    .indexOf(regexCleanup(term));

  const relatedTopic = terms[relatedTopicIndex];
  const targetDistribution = get(relatedTopic, `recommended_mentions`);
  const targetBounds = statBandMentions.calculate(targetDistribution);
  const targetLow = targetBounds.bandLow;
  const targetHigh = targetBounds.bandHigh;

  let renderIcon, renderMessage;
  if (currentDistribution < targetLow) {
    renderIcon = <IconSvg name="rise" color="green" size="28" box="16" />;
    renderMessage = `You are getting close to the suggested distribution for this topic! You want to use ${term} a few more times in this piece.`;
  } else if (
    currentDistribution >= targetLow &&
    (currentDistribution <= targetHigh || !targetHigh)
  ) {
    renderIcon = (
      <IconSvg color="blue" name="check-circle" size="28" box="16" />
    );
    renderMessage =
      'You are within the suggested range for this topic! Lookin’ good, don’t over do it!';
  } else if (currentDistribution > targetHigh) {
    renderIcon = <IconSvg color="orange" name="scissors" size="28" box="16" />;
    renderMessage =
      "You've used this topic more than suggested. Consider replacing an instance with another related topic!";
  }
  return (
    <Notification key={key}>
      <NotifHeader>
        <NotifIcon>{renderIcon}</NotifIcon>
        <NotifText>{renderMessage}</NotifText>
      </NotifHeader>
      <NotifRow>
        <div style={{ flexGrow: 1 }}>{term}</div>
        <Distribution value={currentDistribution} style={{ marginLeft: 8 }} />
        <Distribution value={targetDistribution} style={{ marginLeft: 8 }} />
      </NotifRow>
    </Notification>
  );
};

const renderWordCount = ({ key, wordCountTarget, wordCount }) => {
  const wordCountTargetHalf = wordCountTarget / 2;
  if (wordCount < wordCountTargetHalf) return null;
  let renderIcon, renderMessage;
  if (wordCount >= wordCountTarget) {
    renderIcon = <IconSvg name="target" color="blue" size="28" box="16" />;
    renderMessage = 'You have hit your target word count!';
  } else if (wordCount >= wordCountTargetHalf) {
    renderIcon = <IconSvg name="target" color="yellow" size="28" box="16" />;
    renderMessage = 'You are halfway to your word count target!';
  }

  return (
    <Notification key={key}>
      <NotifHeader>
        <NotifIcon>{renderIcon}</NotifIcon>
        <NotifText>{renderMessage}</NotifText>
      </NotifHeader>
      <NotifRow>
        <BarWordCount
          style={{ width: '100%' }}
          at={wordCount}
          over={wordCountTarget}
        />
      </NotifRow>
    </Notification>
  );
};

const renderContentScore = ({
  key,
  contentScoreAverage,
  contentScoreTarget,
  contentScoreBest,
  contentScore,
}) => {
  if (contentScore < contentScoreAverage) return null;

  let renderIcon, renderMessage;
  if (contentScore >= contentScoreTarget) {
    renderIcon = <IconSvg color="blue" name="badge" size="28" box="16" />;
    renderMessage = 'You have exceeded the target Content Score!';
  } else if (contentScore >= contentScoreAverage) {
    renderIcon = <IconSvg color="green" name="badge" size="28" box="16" />;
    renderMessage =
      'You have entered the average Content Score range. Keep up the good work!';
  }

  return (
    <Notification key={key}>
      <NotifHeader>
        <NotifIcon>{renderIcon}</NotifIcon>
        <NotifText>{renderMessage}</NotifText>
      </NotifHeader>
      <NotifRow>
        <BarContentScore
          style={{ width: '100%' }}
          at={contentScore}
          over={contentScoreTarget}
          contentScoreAverage={contentScoreAverage}
          contentScoreTarget={contentScoreTarget}
          contentScoreBest={contentScoreBest}
        />
      </NotifRow>
    </Notification>
  );
};

const renderNotif = (
  notif,
  {
    key,
    terms,
    matches,
    wordCountTarget,
    contentScoreAverage,
    contentScoreTarget,
    contentScoreBest,
  },
) => {
  if (notif.type === 'distribution') {
    return renderDist({ key, matches, terms, term: notif.term });
  } else if (notif.type === 'word-count') {
    return renderWordCount({
      key,
      wordCountTarget,
      wordCount: notif.wordCount,
    });
  } else if (notif.type === 'content-score') {
    return renderContentScore({
      key,
      contentScoreAverage,
      contentScoreTarget,
      contentScoreBest,
      contentScore: notif.contentScore,
    });
  }

  return null;
};

const ToolkitTabFeed = ({ decors, appData, ...props }) => {
  const matches = useMemo(
    () => get(decors, 'decorRelatedTopics') || {},
    [decors],
  );
  const contentScoreAverage = useMemo(
    () => Math.round(get(appData, 'scores.metrics.cs_avg', 0)),
    [appData],
  );
  const contentScoreTarget = useMemo(
    () => Math.round(get(appData, 'scores.metrics.cs_target', 0)),
    [appData],
  );
  const contentScoreBest = useMemo(
    () => Math.round(get(appData, 'scores.metrics.cs_max', 0)),
    [appData],
  );
  const wordCountTarget = useMemo(
    () => Math.round(get(appData, 'scores.metrics.wc_target', 0)),
    [appData],
  );
  const terms = useMemo(() => get(appData, 'scores.kg.data') || [], [appData]);
  const notifications = useMemo(
    () =>
      Object.keys(props.notifications).sort(
        (a, b) =>
          props.notifications[b].lastUpdated -
          props.notifications[a].lastUpdated,
      ),
    [props.notifications],
  );

  return useMemo(() => {
    if (notifications.length === 0) {
      return (
        <div>
          <Notification style={{ border: 'none', margin: 0 }}>
            <NotifHeader>
              <NotifIcon>
                <IconSvg name="star" color="yellow" size="16" />
              </NotifIcon>
              <NotifText>Insights will appear as you type...</NotifText>
            </NotifHeader>
          </Notification>
        </div>
      );
    }
    return (
      <div>
        {notifications
          .map(id => {
            const notif = get(notifications, regexCleanup(id)) || {};

            return renderNotif(notif, {
              key: `notif-${id}`,
              terms,
              matches,
              wordCountTarget,
              contentScoreAverage,
              contentScoreTarget,
              contentScoreBest,
            });
          })
          .filter(Boolean)}
      </div>
    );
  }, [decors, appData, notifications]);
};

ToolkitTabFeed.propTypes = {
  appData: PropTypes.object,
  decors: PropTypes.object,
  notifications: PropTypes.object,
};

export default ToolkitTabFeed;
