import { get } from 'lodash';

import Agent from '../Agent';
import { isProd, isStaging } from '../../../utils/isEnv';
import { hash } from '../../../utils/misc';
import serializePendoSubscriptionInfo from '../../../utils/serializePendoSubscriptionInfo';

class Pendo extends Agent {
  isReady() {
    return (
      !!window.pendo &&
      typeof window.pendo.initialize === 'function' &&
      typeof window.pendo.track === 'function'
    );
  }

  serializePendoIdentity(user) {
    const subscriptionInfo = serializePendoSubscriptionInfo(user.subscriptions);

    return {
      visitor: {
        id: hash(user.email),
        name: user.fullName,
        full_name: user.fullName,
        email: user.email,
        createdAt: get(user, 'createdAt'),
        free: user.free,
        appsumo: user.appsumo,
        classic: user.classic,
        standard: user.standard,
        premium: user.premium,
        highestInventoryLevel: user.highestInventoryLevel,
        ...subscriptionInfo,
      },
      account: {
        id: get(user, 'org.id') || null,
        name: get(user, 'org.name'),
        slug: get(user, 'org.slug'),
        createdAt: get(user, 'org.createdAt'),
        sfdcAccountId: get(user, 'org.sfdcAccountId'),
        recurlyAccountCode: get(user, 'org.recurlyAccountCode'),
        tier: user.tier,
        addressAccount: this.serializeAddress(get(user, 'address.account')),
        addressBilling: this.serializeAddress(get(user, 'address.billing')),
        addressShipping: this.serializeAddress(get(user, 'address.shipping')),
      },
    };
  }

  canInit(user) {
    return this.isReady() && super.canInit(user);
  }

  init(user) {
    if (!this.canInit(user, this)) return;

    window.pendo.identify(this.serializePendoIdentity(user));
  }
  update(user) {
    if (!this.canInit(user, this)) return;
    window.pendo.updateOptions(this.serializePendoIdentity(user));
  }

  clear() {
    if (!this.isReady()) {
      return;
    }
    window.pendo.clearSession();
  }

  initAnonymous() {
    if (!this.isReady()) {
      return;
    }

    window.pendo.initialize();
    return;
  }

  track(data) {
    if (!this.isReady()) {
      console.warn('Pendo cannot track events.');
      return;
    }

    const action = get(data, 'action');

    if (!isProd() && !isStaging()) {
      return;
    }

    window.pendo.track(action, data);
  }
}

export default Pendo;
