import { isEmpty } from 'lodash';
import { stripUrl } from '@marketmuse/utilities';
import {
  StrictTypedTypePolicies,
  SiteLevel,
} from '@marketmuse/config/types/papi';

const urlNormalizationPolicy = {
  fields: {
    url: {
      merge(existing, incoming) {
        if (!isEmpty(incoming)) {
          return stripUrl(incoming);
        }
        if (!isEmpty(existing)) {
          return stripUrl(existing);
        }
        return existing;
      },
      read(existing) {
        if (!isEmpty(existing)) {
          return stripUrl(existing);
        }
        return existing;
      },
    },
  },
};

const sitePolicy: StrictTypedTypePolicies['Site'] = {
  merge: true,
  fields: {
    level: {
      merge(existing, incoming) {
        if (!isEmpty(incoming)) {
          return incoming;
        }
        if (!isEmpty(existing)) {
          return existing;
        }

        return existing || SiteLevel.NONE;
      },
      read(existing) {
        if (isEmpty(existing)) {
          return SiteLevel.NONE;
        }
        return existing;
      },
    },
  },
};

const tagPolicy: StrictTypedTypePolicies['Tag'] = {
  fields: {
    urlTerms: {
      merge(existing = [], incoming: any[]) {
        return [...existing, ...incoming];
      },
    },
  },
};

const documentPolicy: StrictTypedTypePolicies['Document'] = {
  fields: {
    tags: {
      merge(existing = [], incoming: any[]) {
        return [...existing, ...incoming];
      },
    },
  },
};

const typePolicies: StrictTypedTypePolicies = {
  OrganicSerpItem: urlNormalizationPolicy,
  OrganicSerpItemPageKgData: urlNormalizationPolicy,
  OrganicSerpItemPage: urlNormalizationPolicy,
  OrganicSerpItemWithIntent: urlNormalizationPolicy,
  UserProvidedPage: urlNormalizationPolicy,
  WebsiteSerpItemPagesKgData: urlNormalizationPolicy,
  Site: sitePolicy,
  Tag: tagPolicy,
  Document: documentPolicy,
};

export default typePolicies;
