export const CKE_TOOLBAR_ITEMS = {
  DIVIDER: '|',
  UNDO: 'undo',
  REDO: 'redo',
  ITALIC: 'italic',
  BOLD: 'bold',
  UNDERLINE: 'underline',
  STRIKETHROUGH: 'strikethrough',
  CODE: 'code',
  SUPERSCRIPT: 'superscript',
  SUBSCRIPT: 'subscript',
  LINK: 'link',
  ALIGNMENT: 'alignment',
  OUTDENT: 'outdent',
  INDENT: 'indent',
  BLOCK_QUOTE: 'blockQuote',
  NUMBERED_LIST: 'numberedList',
  BULLETED_LIST: 'bulletedList',
  CODE_BLOCK: 'codeBlock',
  FIND_AND_REPLACE: 'findAndReplace',
  FONT_SIZE: 'fontSize',
  FONT_FAMILY: 'fontFamily',
  FONT_COLOR: 'fontColor',
  FONT_BACKGROUND_COLOR: 'fontBackgroundColor',
  HIGHLIGHT: 'highlight',
  HORIZONTAL_LINE: 'horizontalLine',
  SOURCE_EDITING: 'sourceEditing',
  REMOVE_FORMAT: 'removeFormat',
  SELECT_ALL: 'selectAll',
  HEADING: 'heading',
  TODO_LIST: 'todoList',
  TABLE: 'insertTable',
  IMAGE_INSERT: 'insertImage',
  IMAGE_UPLOAD: 'uploadImage',
  MEDIA_EMBED: 'mediaEmbed',
  RESTRICTED_EDITING: 'restrictedEditing',
  STANDARD_EDITING: 'restrictedEditingException',
  EXPORT: 'export',
} as const;
