import React from 'react';
import {
  InventoryVersion,
  Site,
  Permission,
  PermissionResource,
  PermissionAction,
  PermissionStatus,
} from '@marketmuse/config/types/papi';
import { InventoryLimitedProps } from '@marketmuse/config/types/inventory';
import { Toaster } from '@marketmuse/components';
import { SiteMock } from '@marketmuse/data-papi';
import { ProviderMock } from '@marketmuse/data-state';
import {
  RootState,
  SliceTypeAppResearch,
  UserSuiteState,
} from '@marketmuse/data-state/types';
import { initialState as appOptimizeInitialState } from '@marketmuse/data-state/appOptimize';
import { documentsTablesInitialState } from '@marketmuse/data-state/documentsTables';
import * as slices from '@marketmuse/data-state/appResearch/slices';
import { paymentInitialState } from '@marketmuse/data-state/payment';
import ResearchProvider from './ResearchProvider';

const MessageInventoryLimitedMock = () => <div></div>;

const initialState: SliceTypeAppResearch = slices.initialState;

const ResearchProviderMock: React.FC<{
  userState?: UserSuiteState;
  siteState?: Site;
  versionsState?: InventoryVersion[];
  appResearchState?: SliceTypeAppResearch;
  permissionsState?: Permission[];
  renderMessageInventoryLimited: React.ComponentType<InventoryLimitedProps>;
  mocks?: any[];
}> = ({
  appResearchState,
  children,
  mocks,
  permissionsState,
  renderMessageInventoryLimited = MessageInventoryLimitedMock,
  siteState,
  userState,
  versionsState,
}) => {
  const state: RootState = {
    user: userState || {
      id: '',
      email: 'storybook@marketmuse.com',
      fullName: 'Storybook Test User',
    },
    filter: {
      site: siteState || SiteMock.build(),
      versions: versionsState || [],
    },
    appResearch: appResearchState || initialState,
    appOptimize: appOptimizeInitialState,
    misc: {},
    payment: paymentInitialState,
    permissions: permissionsState || [
      {
        resource: PermissionResource.RESEARCH_KEYWORDS,
        action: PermissionAction.ACCESS_DATA,
        status: PermissionStatus.ALLOW,
      },
      {
        resource: PermissionResource.RESEARCH_QUESTIONS,
        action: PermissionAction.ACCESS_DATA,
        status: PermissionStatus.ALLOW,
      },
      {
        resource: PermissionResource.RESEARCH_CONNECT,
        action: PermissionAction.ACCESS_DATA,
        status: PermissionStatus.ALLOW,
      },
      {
        resource: PermissionResource.RESEARCH_SERP_X_RAY,
        action: PermissionAction.ACCESS_DATA,
        status: PermissionStatus.ALLOW,
      },
      {
        resource: PermissionResource.RESEARCH_TOPIC_NAVIGATOR,
        action: PermissionAction.ACCESS_FULL_DATA,
        status: PermissionStatus.ALLOW,
      },
      {
        resource: PermissionResource.RESEARCH_TOPIC_NAVIGATOR,
        action: PermissionAction.EXPORT,
        status: PermissionStatus.ALLOW,
      },
    ],
    documentsTables: documentsTablesInitialState,
  };

  return (
    <ProviderMock state={state} mocks={mocks}>
      <ResearchProvider
        renderMessageInventoryLimited={renderMessageInventoryLimited}
      >
        {children}
      </ResearchProvider>
      <Toaster />
    </ProviderMock>
  );
};

export default ResearchProviderMock;
