import { FC, useCallback, ReactNode, ComponentType } from 'react';

import { Language } from '@marketmuse/config/types/papi';
import { InventoryLimitedProps } from '@marketmuse/config/types/inventory';
import { ACTION_TYPES, MISC_SIMPLE_KEYS } from '@marketmuse/data-state';
import {
  useStoreDispatch,
  useStoreSelector,
} from '@marketmuse/data-state/hooks';
import {
  ResearchDataConnectInput,
  ResearchSnapshotInput,
  ResearchTopicNavigatorViewUpdate,
  ResearchViews,
  ViewHeatmapFiltersState,
  ViewHeatmapSliceState,
  ViewSerpXraySliceState,
} from '@marketmuse/data-state/types';
import { miscActions } from '@marketmuse/data-state/misc';
import { RESEARCH_ACTIONS } from '@marketmuse/data-state/appResearch';
import { viewsActions } from '@marketmuse/data-state/appResearch/slices';
import { parseDomain, getCountryEnum } from '@marketmuse/utilities';

import { ResearchActionsContext } from '../context';
import {
  useAddToInventory,
  useResearchPermissions,
  useResearchUiState,
  useExportTopicNavigator,
  useExportConnect,
  useExportHeatmap,
  useExportSerpXray,
} from '../hooks';
import { IResearchActionsContext } from '../types';

const ResearchActionsProvider: FC<{
  children?: ReactNode;
  renderMessageInventoryLimited: ComponentType<InventoryLimitedProps>;
}> = ({ children, renderMessageInventoryLimited }) => {
  const {
    id: siteId,
    domain: siteDomain,
    defaultSerpCountry: siteCountry,
  } = useStoreSelector(state => state?.filter?.site);
  const dispatch = useStoreDispatch();

  const orgTopicsAllocated = useStoreSelector(
    state => state?.user?.org?.topicsAllocated,
  );
  const orgTopicsMax = useStoreSelector(state => state?.user?.org?.topicsMax);
  const siteTopicsAllocated = useStoreSelector(
    state => state?.filter?.site?.topicsAllocated,
  );
  const siteTopicsCurrent = useStoreSelector(
    state => state?.misc?.counts?.topicsAll,
  );

  const { competitorDomains, networkDomains, term, url } = useResearchUiState();
  const {
    canAddToInventory,
    canExportConnect,
    canExportHeatmap,
    canExportSerpXray,
    canExportTopicNavigator,
    hasViableSiteInventory,
  } = useResearchPermissions();

  const performAppQuery = payload => {
    dispatch(RESEARCH_ACTIONS.researchTaskQueryUpsert(payload));
  };

  const matchQueryLocaleToSite = () => {
    dispatch(
      RESEARCH_ACTIONS.researchTaskQueryUpsert({
        siteId,
        term,
        url,
        country: getCountryEnum(siteCountry),
        language: Language.EN,
        competitorDomains,
        networkDomains,
      }),
    );
  };

  const updateConnectDomains = useCallback(
    (payload: ResearchDataConnectInput) => {
      dispatch(RESEARCH_ACTIONS.researchTaskConnectDomains(payload));
    },
    [dispatch],
  );

  const updateTopicNavigatorFilters = useCallback(
    (payload: Pick<ResearchTopicNavigatorViewUpdate, 'view' | 'filters'>) => {
      switch (payload.view) {
        case ResearchViews.TOPIC_MODEL:
          dispatch(viewsActions.topicModel.filtersSet(payload.filters));
          break;
        case ResearchViews.KEYWORDS:
          dispatch(viewsActions.keywords.filtersSet(payload.filters));
          break;
        case ResearchViews.QUESTIONS:
          dispatch(viewsActions.questions.filtersSet(payload.filters));
          break;
        case ResearchViews.REFLECT:
          dispatch(viewsActions.reflect.filtersSet(payload.filters));
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  const updateTopicNavigatorSort = useCallback(
    (payload: Pick<ResearchTopicNavigatorViewUpdate, 'view' | 'sort'>) => {
      switch (payload.view) {
        case ResearchViews.TOPIC_MODEL:
          dispatch(viewsActions.topicModel.sortSet(payload.sort));
          break;
        case ResearchViews.KEYWORDS:
          dispatch(viewsActions.keywords.sortSet(payload.sort));
          break;
        case ResearchViews.QUESTIONS:
          dispatch(viewsActions.questions.sortSet(payload.sort));
          break;
        case ResearchViews.REFLECT:
          dispatch(viewsActions.reflect.sortSet(payload.sort));
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  const updateHeatmapFilter = useCallback(
    (payload: ViewHeatmapFiltersState) => {
      dispatch(RESEARCH_ACTIONS.researchTaskWebsiteHeatmapFilters(payload));
    },
    [dispatch],
  );

  const updateHeatmapMode = useCallback(
    (payload: ViewHeatmapSliceState['mode']) => {
      dispatch(viewsActions.heatmap.modeSet(payload));
    },
    [dispatch],
  );

  const updateHeatmapSort = useCallback(
    (payload: ViewHeatmapSliceState['sort']) => {
      dispatch(viewsActions.heatmap.sortSet(payload));
    },
    [dispatch],
  );

  const updateSerpXrayFilter = useCallback(
    (payload: ViewSerpXraySliceState) => {
      dispatch(viewsActions.xray.filtersSet(payload.filters));
    },
    [dispatch],
  );

  const loadAppQuery = (payload: ResearchSnapshotInput) => {
    dispatch(RESEARCH_ACTIONS.researchTaskQueryLoad(payload));
  };

  const exportTopicNavigator = useExportTopicNavigator({
    canExport: canExportTopicNavigator,
    term,
    url,
  });

  const exportConnect = useExportConnect({
    canExport: canExportConnect,
    term,
    url,
  });

  const exportHeatmap = useExportHeatmap({
    canExport: canExportHeatmap,
    term,
    url,
  });

  const exportSerpXray = useExportSerpXray({
    canExport: canExportSerpXray,
    term,
    url,
  });

  const addToInventory = useAddToInventory({
    canAddToInventory,
    hasViableSiteInventory,
    siteId,
    topicCounts: {
      orgTopicsAllocated: orgTopicsAllocated || 0,
      orgTopicsMax: orgTopicsMax || 0,
      siteTopicsAllocated: siteTopicsAllocated || 0,
      siteTopicsCurrent: siteTopicsCurrent || 0,
    },
    renderMessageInventoryLimited,
  });

  const onTriggerSiteLevelHeatmap = (term: string) => {
    dispatch(
      viewsActions.heatmap.modeAndFiltersSet({
        mode: 'website',
        filters: {
          include: parseDomain(siteDomain),
          exclude: [],
        },
      }),
    );
    dispatch(
      RESEARCH_ACTIONS.researchTaskQueryUpsert({
        siteId,
        term,
        url,
        country: getCountryEnum(siteCountry),
        language: Language.EN,
        competitorDomains,
        networkDomains,
      }),
    );
  };

  const openCalendly = () =>
    dispatch(
      miscActions.setSimple({
        key: MISC_SIMPLE_KEYS.calendlyPremium,
        value: true,
      }),
    );

  const openSubscriptionFlow = () => {
    dispatch({
      type: ACTION_TYPES.TOGGLE_DYNAMIC,
      payload: {
        status: true,
        id: 'subscriptionManagement',
        data: {
          initialScreen: 'plans',
        },
      },
    });
  };

  const actions: IResearchActionsContext = {
    addToInventory,
    exportConnect,
    exportHeatmap,
    exportSerpXray,
    exportTopicNavigator,
    loadAppQuery,
    matchQueryLocaleToSite,
    onTriggerSiteLevelHeatmap,
    openCalendly,
    openSubscriptionFlow,
    performAppQuery,
    updateConnectDomains,
    updateHeatmapFilter,
    updateHeatmapMode,
    updateHeatmapSort,
    updateSerpXrayFilter,
    updateTopicNavigatorFilters,
    updateTopicNavigatorSort,
  };

  return (
    <ResearchActionsContext.Provider value={actions}>
      {children}
    </ResearchActionsContext.Provider>
  );
};

export default ResearchActionsProvider;
