import { createSlice, combineReducers, PayloadAction } from '@reduxjs/toolkit';
import { Site } from '@marketmuse/config/types/papi';
import * as ACTION_TYPES from './filter.actionTypes';
import { siteState, versionsState } from './filter.initialState';

type SiteUpdate = {
  key: keyof Site;
  value: Site[keyof Site];
};

export const siteSlice = createSlice({
  name: 'site',
  initialState: siteState,
  reducers: {
    updateField: (state, action: PayloadAction<SiteUpdate>) => {
      const key = action.payload.key;
      const value = action.payload.value;

      // eslint-disable-next-line
      // @ts-ignore
      state[key] = value;
    },
  },
});

export const versionsSlice = createSlice({
  name: 'versions',
  initialState: versionsState,
  reducers: {
    clear: () => {
      return [];
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      action => action.type === ACTION_TYPES.INVENTORY_VERSIONS_SAVE,
      (_, action) => {
        return action.payload;
      },
    );
  },
});

export const filterReducer = combineReducers({
  site: siteSlice.reducer,
  versions: versionsSlice.reducer,
});
