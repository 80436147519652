import { createSlice, PayloadAction, original } from '@reduxjs/toolkit';
import { merge } from 'lodash/fp';
import { User } from '@marketmuse/config/types/papi';
import {
  serializeAccessibleInventories,
  sanitizeOrgSlug,
} from '@marketmuse/utilities';
import * as ACTION_TYPES from '../actionTypes';
import { initialState } from './user.initialState';

type UserUpdate = {
  key: keyof User;
  value: User[keyof User];
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<User>) => {
      state = action.payload;
    },
    updateField: (state, action: PayloadAction<UserUpdate>) => {
      const key = action.payload.key;
      const value = action.payload.value;

      // eslint-disable-next-line
      // @ts-ignore
      state[key] = value;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        action => action.type === ACTION_TYPES.ORG_DATA_SAVE,
        (state, action) => {
          const nextSites = serializeAccessibleInventories(
            action.payload.sites,
          );
          const orgBase = original(state?.org);
          const org = merge(orgBase, {
            ...action.payload,
            sites: merge(orgBase.sites, nextSites),
          });

          return {
            ...state,
            org: {
              ...org,
              users: action.payload.users || org.users,
            },
          };
        },
      )
      .addMatcher(
        action => action.type === ACTION_TYPES.INVENTORY_FIELDS_UPDATE,
        (state, action) => {
          const sites = state.org?.sites || [];
          const siteIndex = sites.findIndex(
            site => site.id === action.payload.siteId,
          );
          if (siteIndex === -1 || !sites) {
            return state;
          }
          const sitesNew = [...sites];
          sitesNew[siteIndex] = merge(
            sitesNew[siteIndex],
            action.payload.updatedFields,
          );

          return {
            ...state,
            org: {
              ...state.org,
              sites: sitesNew,
            },
          };
        },
      )
      .addMatcher(
        action => action.type === ACTION_TYPES.SIGN_OUT,
        () => {
          return { ...initialState };
        },
      )
      .addMatcher(
        action => action.type === ACTION_TYPES.ORG_DATA_SITE_DELETE,
        (state, action) => {
          return {
            ...state,
            org: {
              ...state.org,
              sites: state.org.sites.filter(
                site => site.id !== action.payload.siteId,
              ),
            },
          };
        },
      )

      .addMatcher(
        action => action.type === ACTION_TYPES.USER_SET_IDENTITY,
        (state, action) => {
          return {
            ...action.payload.user,
            org: {
              ...(action.payload.user?.org || {}),
              slug: sanitizeOrgSlug(action.payload.user?.org?.slug),
              sites: serializeAccessibleInventories(
                action.payload.user?.org?.sites,
              ),
            },
          };
        },
      );
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
