import React, { useMemo, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { PAPI } from '@marketmuse/config/types';
import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state/types';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { ErrorBoundary } from '@marketmuse/components';

import useIdentityProvider from '../../providers/IdentityProvider/useIdentityProvider';
import useIdentitySuite from '../../hooks/auth/useIdentitySuite';
import ScreenLoader from '../../components/ScreenLoader';

import LayoutProtected from '../../containers/Layout/LayoutProtected';
import LayoutGated from '../../containers/Layout/LayoutGated';

const checkGateMode = ({
  state,
  dunning,
  plan,
}: {
  plan: PAPI.SubscriptionPlanType | null;
  state: PAPI.SubscriptionState;
  dunning: boolean;
}) => {
  // if plan is free, never show status screen
  if (plan === PAPI.SubscriptionPlanType.FREE) {
    return null;
  }

  // hide dunning only if custom, or free
  if (dunning && plan !== PAPI.SubscriptionPlanType.CUSTOM) {
    return 'DUNNING';
  }

  // other cases we only care of state is expired
  if (state !== PAPI.SubscriptionState.EXPIRED) {
    return null;
  }

  if (plan === PAPI.SubscriptionPlanType.RESEARCH_TRIAL) {
    return 'EXPIRED_TRIAL';
  }

  return 'EXPIRED';
};

/**
 * The `Private` acts as a wrapper to verify only authenticated users
 * in good subscription status can render the protected layout.
 *
 * Business Logic:
 * - Redirects users if they have not yet authorized/authenticated.
 * - Display a loading screen while authentication checks are in progress.
 * - Determines if the user's subscription status requires gating.
 * - Renders the the full layout if user is authenticated and has a valid subscription.
 *
 * @returns {JSX.Element} Appropriate layout based on the user's auth/subscription status
 */
export const Private = () => {
  const userLoaded = useStoreSelector(
    state => state.misc?.[MISC_SIMPLE_KEYS.userLoaded],
  );
  const initialPath = useStoreSelector(
    state => state.misc?.[MISC_SIMPLE_KEYS.initialPath],
  );
  const logoutInitiated = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.initiateLogout],
  );
  const subscription = useStoreSelector(state => state.payment.subscription);
  const gateMode = checkGateMode({
    dunning: subscription?.dunning || false,
    state: subscription?.state || PAPI.SubscriptionState.PAID,
    plan: subscription?.subscriptionPlan?.type || null,
  });
  const { siteId, orgId } = useIdentitySuite();
  const { isSignedIn, isLoading } = useIdentityProvider();
  const navigate = useNavigate();

  const showProtected = useMemo(() => {
    const failureConditions = [
      logoutInitiated,
      !orgId,
      !siteId,
      !isSignedIn,
      !initialPath,
    ];

    if (failureConditions.filter(Boolean).length > 0) {
      return false;
    }

    return true;
  }, [initialPath, isSignedIn, logoutInitiated, orgId, siteId]);

  useEffect(() => {
    if (isLoading || userLoaded || showProtected) {
      return;
    }

    if (isSignedIn) {
      navigate('/post-login');
    } else {
      navigate('/login');
    }
  }, [userLoaded, showProtected, isSignedIn, navigate, isLoading]);

  // If user is already loaded and other conditions are met render the protected layout
  if ((!showProtected || isLoading) && !userLoaded) {
    return <ScreenLoader.Auth />;
  }

  if (gateMode) {
    return <LayoutGated mode={gateMode} />;
  }

  return (
    <LayoutProtected>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </LayoutProtected>
  );
};
