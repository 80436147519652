import { useEffect, useRef, useState } from 'react';
import { captureMessage } from '@sentry/react';
import { useSession, useSessionList } from '@clerk/clerk-react';
import { SessionResource } from '@clerk/types';
import { TOKEN_KEYS } from '@marketmuse/config/configs';
import { Text, Box, Button, toast } from '@marketmuse/components';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state/types';

import useLogout from '../../hooks/auth/useLogout';

const toastOptions = {
  duration: 60_000 * 5, // 5 minutes
  id: 'auth-error',
};

const useSessionData = () => {
  const logoutInitiated = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.initiateLogout],
  );
  const sessionRef = useRef<{
    last: Array<SessionResource> | null;
    now: Array<SessionResource> | null;
  }>({ last: null, now: null });
  const [sessionId, setSessionId] = useState<string | null>(null);
  const handleLogout = useLogout();
  const { session, isLoaded, isSignedIn } = useSession();
  const { sessions } = useSessionList();

  useEffect(() => {
    if (isLoaded && isSignedIn && session && !sessionId) {
      sessionStorage.removeItem(TOKEN_KEYS.GHOST);
      sessionStorage.removeItem(TOKEN_KEYS.GUEST);
      setSessionId(session.id);
    }
  }, [isLoaded, isSignedIn, session, sessionId]);

  useEffect(() => {
    sessionRef.current.now = sessions;

    if (!sessionRef.current) {
      return;
    }

    if (
      !logoutInitiated &&
      sessionRef.current.last?.length &&
      !sessionRef.current.now?.length
    ) {
      toast.error(
        <Box>
          <Text as="p">
            <strong>Your session is no longer active.</strong>
          </Text>

          <Box className="mt-2 flex flex-row justify-between">
            <Text>Please save any open work, and&nbsp;log&nbsp;out.</Text>
            <Button
              variant="red100"
              className={'whitespace-nowrap'}
              onClick={() => {
                setSessionId(null);
                handleLogout();
              }}
            >
              Log out
            </Button>
          </Box>
        </Box>,
        toastOptions,
      );

      captureMessage(`Session ended`, {
        level: 'log',
        fingerprint: ['session-ended-early'],
        tags: { 'session-ended': 'session-ended' },
        extra: { sessions: sessionRef.current.last },
      });
    }

    sessionRef.current.last = sessions;
  }, [sessions, logoutInitiated, handleLogout]);

  return session?.id;
};

export default useSessionData;
