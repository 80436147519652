import { AdWordsItem, KnowledgeGraphItem } from '@marketmuse/config/types/papi';

type VariantsParams = Pick<KnowledgeGraphItem, 'term' | 'recommendedMentions'> &
  Pick<AdWordsItem, 'variants'>;
type Variants = Pick<KnowledgeGraphItem, 'term' | 'recommendedMentions'>[];
type AllVariants = { [key: string]: Variants };

const serializeBulkAdwordsVariants = (items: VariantsParams[]) => {
  const variants: Variants = [];
  const all_variants: AllVariants = {};

  return items.reduce(
    (acc, item) => {
      const { variants: kgVariants, recommendedMentions, term } = item;
      const termVariantsUnsafe = all_variants[term] || [];
      const termVariants = Array.isArray(termVariantsUnsafe)
        ? termVariantsUnsafe
        : [];
      const parsedVariants = (kgVariants || []).map(variant => ({
        term: variant.term,
        recommended_mentions: recommendedMentions,
      }));

      return {
        variants: [...acc.variants, ...parsedVariants],
        all_variants: {
          ...acc.all_variants,
          [term]: [...termVariants, ...parsedVariants],
        },
      };
    },
    {
      variants,
      all_variants,
    },
  );
};

export default serializeBulkAdwordsVariants;
