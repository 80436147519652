import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { isNil, trim } from 'lodash';

import { sanitizeOrgSlug } from '@marketmuse/utilities';
import { useStoreDispatch } from '@marketmuse/data-state/hooks';

import { selectSite } from '../actions/siteActions';
import { useSuiteNavigate } from './useSuiteNavigate';

function createPath(slug, nextSiteId, nextSlug, siteId, path) {
  let newPathName = `/${slug}/${nextSiteId}`;

  if (slug !== nextSlug && nextSlug) {
    const lastPath = new RegExp('^/' + slug);
    newPathName = newPathName.replace(lastPath, `/${nextSlug}`);
  }
  if (siteId !== nextSiteId) {
    newPathName = newPathName.replace(`/${siteId}`, `/${nextSiteId}`);
  }

  if (path) {
    newPathName += `/${path}`;
  }

  const finalPath = `a/${trim(newPathName, '/')}`;
  return finalPath;
}

const useSiteChange = ({
  resetApps = false,
  siteId: _siteId,
  skipFetchPermissions = false,
  slug: _slug,
}: {
  resetApps: boolean;
  siteId: string;
  skipFetchPermissions: boolean;
  slug: string;
}) => {
  const dispatch = useStoreDispatch();
  const navigate = useSuiteNavigate();
  const { siteId: siteIdParam, slug: slugParam } = useParams();
  const siteId = siteIdParam || _siteId;
  const slug = sanitizeOrgSlug(slugParam || _slug);

  const setSite = useCallback(
    ({ siteId: nextSiteId, slug: _nextSlug, path }) => {
      if (isNil(nextSiteId)) {
        return;
      }

      const nextSlug = sanitizeOrgSlug(_nextSlug || slug);
      const finalPath = createPath(slug, nextSiteId, nextSlug, siteId, path);

      dispatch(selectSite({ id: nextSiteId, resetApps, skipFetchPermissions }));
      navigate(finalPath, { root: true });
    },
    [navigate, slug, siteId, dispatch, resetApps, skipFetchPermissions],
  );

  return setSite;
};

export default useSiteChange;
