import { combineReducers } from 'redux';
import { persistReducer } from 'reduxjs-toolkit-persist';
import hardSet from 'reduxjs-toolkit-persist/lib/stateReconciler/hardSet';

import localForage from 'localforage';

import { REDUCERS } from '@marketmuse/data-state';

import persistableReducer from './persistableReducer';
import filterReducer from './filterReducer';
import toolsReducer from './toolsReducer';
import settingsReducer from './settingsReducer';
import appsReducer from './appsReducer';

export const generalPersistConfig = {
  key: 'persist',
  storage: localForage,
  stateReconciler: hardSet,
};

export const userPersistConfig = {
  key: 'user',
  storage: localForage,
  stateReconciler: hardSet,
};

export const adminPersistConfig = {
  key: 'admin',
  storage: localForage,
  stateReconciler: hardSet,
};

export const appOptimizePersistConfig = {
  key: 'appOptimize',
  storage: localForage,
  stateReconciler: hardSet,
};

const rootReducer = combineReducers({
  admin: persistReducer(adminPersistConfig, REDUCERS.adminReducer),
  appOptimize: persistReducer(
    appOptimizePersistConfig,
    REDUCERS.appOptimizeReducer,
  ),
  appResearch: REDUCERS.appResearchReducer,
  apps: appsReducer,
  documentsTables: REDUCERS.documentsTablesReducer,
  filter: filterReducer,
  loadings: REDUCERS.loadingsReducer,
  misc: REDUCERS.miscReducer,
  payment: REDUCERS.paymentReducer,
  permissions: REDUCERS.permissionsReducer,
  persist: persistReducer(generalPersistConfig, persistableReducer),
  savedViews: REDUCERS.savedViewsReducer,
  settings: settingsReducer,
  tools: toolsReducer,
  user: REDUCERS.userReducer,
});

export default rootReducer;
