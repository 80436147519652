import { FC } from 'react';
import { merge } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { Box } from '../../Box';
import { Pagination, IPaginationProps } from '../../Pagination';
import { useTableContext } from '../useTableContext';

const TablePagination: FC<{
  rowsControl?: Array<number>;
  classNames?: Array<string> | string;
  paginationProps?: Partial<IPaginationProps>;
}> = ({
  rowsControl = [10, 25, 50, 100, 250],
  classNames,
  paginationProps = {},
}) => {
  const {
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState,
    getPrePaginationRowModel,
    loading,
  } = useTableContext();
  const state = getState();
  const pageIndex = state.pagination.pageIndex;
  const pageSize = state.pagination.pageSize;
  const resultCount = (
    getPrePaginationRowModel()?.rows || []
  ).length.toString();

  return (
    <Box className={twMerge(['shadow', 'flex', 'flex-row'], classNames)}>
      <Pagination
        {...{
          ...(paginationProps || {}),
          wrapperAttrs: merge(
            { 'data-mms--table-pagination': '' },
            paginationProps?.wrapperAttrs,
          ),
        }}
        onChangeLimit={setPageSize}
        resultCount={resultCount}
        onChangePage={page => setPageIndex(page - 1)}
        onNext={nextPage}
        onPrevious={previousPage}
        limit={pageSize}
        page={pageIndex + 1}
        rowsControl={rowsControl}
        loading={loading}
      />
    </Box>
  );
};

export default TablePagination;
