import { get } from 'lodash';

import { tracker } from '../models/tracker/utils/initTracker';
import { userDataLayer } from '../models/tracker/utils/userDataLayer';

export const updateTrackedUser = ({
  user,
  site,
  subscriptions,
  recurlyAccount,
}) => {
  tracker.update(userDataLayer({ user, site, subscriptions, recurlyAccount }));
};

export const initTrackedUser = ({
  user,
  site,
  subscriptions,
  recurlyAccount,
}) => {
  if (get(tracker, 'user.id') !== user?.id) {
    tracker.init(userDataLayer({ user, site, subscriptions, recurlyAccount }));
  } else {
    updateTrackedUser({ user, site, subscriptions, recurlyAccount });
  }
};

export const trackerReset = () => {
  tracker.clear();
};
