import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { toast } from '@marketmuse/components';
import { stripUrl } from '@marketmuse/utilities';
import { TabsStore } from '../config/instances';

type UrlComparison = {
  urlCurrent: string | null;
  urlLast: string | null;
};

const CHANNEL_NAME = 'MM_TAB_URL_SYNC';
const MESSAGE_NAME = 'URL_UPDATE';

const handleMessage = (urlCurrent: string) => (event: MessageEvent) => {
  if (event.data.type === MESSAGE_NAME && event.data.url === urlCurrent) {
    handleToast(urlCurrent);
  }
};

const updateCurrentTab = async (urlCurrent: string | null) => {
  // prepare normalized key
  const urlCurrentStoreKey = urlCurrent
    ? encodeURIComponent(urlCurrent)
    : urlCurrent;

  if (!urlCurrent) {
    return 0;
  }

  // increment tab count
  const last = await TabsStore.getItem<number>(urlCurrentStoreKey);
  const next = last ? last + 1 : 1;

  TabsStore.setItem<number>(urlCurrentStoreKey, next);
  return next;
};

const updateLastTab = async (urlLast: string | null) => {
  // prepare normalized key
  const urlLastStoreKey = urlLast ? encodeURIComponent(urlLast) : urlLast;

  if (!urlLast) {
    return 0;
  }

  // decrement tab count
  const last = await TabsStore.getItem<number>(urlLastStoreKey);
  const next = Math.max(0, last ? last - 1 : 0);

  if (next === 0) {
    TabsStore.removeItem(urlLastStoreKey);
  } else {
    TabsStore.setItem<number>(urlLastStoreKey, next);
  }
  return next;
};

const handleTabChange = async ({ urlLast, urlCurrent }: UrlComparison) => {
  const openTabs: Record<string, number> = {
    [urlCurrent]: await updateCurrentTab(urlCurrent),
    [urlLast]: await updateLastTab(urlLast),
  };

  return openTabs;
};

const handleTabLoad = async ({ urlCurrent, urlLast }: UrlComparison) => {
  const openTabs = await handleTabChange({
    urlCurrent,
    urlLast,
  });

  if (openTabs[urlCurrent] >= 2) {
    // inform user if two tabs open on same url
    handleToast(urlCurrent);
  }
};

const handleToast = (url: string) => {
  const ignores = ['/', '/login', '/signup'];
  if (ignores.some(ignore => ignore === url)) {
    return;
  }

  const segments = stripUrl(url).split('/');
  const isDocument = segments[segments.length - 2] === 'documents';

  // only warn if on a single document
  if (isDocument) {
    toast.warning(
      <>
        <strong>You have this document open in two windows.</strong>
        <span>
          Close other instances to prevent potential conflicts or data loss.
        </span>
      </>,
      {
        duration: 60_000,
        id: CHANNEL_NAME,
      },
    );
  }
};

const TabMonitor = ({ children }) => {
  const location = useLocation();
  const urlRef = useRef(null);

  // monitor changes to the URL
  useEffect(() => {
    const urlCurrent = stripUrl(location.pathname);

    handleTabLoad({ urlCurrent, urlLast: urlRef.current });
    urlRef.current = urlCurrent;
  }, [location.pathname]);

  // Manage Listeners
  useEffect(() => {
    const channel = new BroadcastChannel(CHANNEL_NAME);
    // remove extra data from url
    const urlCurrent = stripUrl(location.pathname);

    // create memory references for binding/unbinding
    const onMessage = handleMessage(urlCurrent);
    const onBeforeUnload = () =>
      handleTabChange({ urlLast: urlRef.current, urlCurrent: null });

    window.addEventListener('beforeunload', onBeforeUnload);
    channel.postMessage({ type: MESSAGE_NAME, url: urlCurrent });
    channel.addEventListener('message', onMessage);

    return () => {
      channel.removeEventListener('message', onMessage);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [location.pathname]);

  return children;
};

export default TabMonitor;
