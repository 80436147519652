import { trim } from 'lodash';
import { GraphQLError } from 'graphql';
const errorsToString = (errors: Array<Error> | ReadonlyArray<GraphQLError>) => {
  const errorString = errors
    .map(error => error.message)
    .join(', ')
    .replace(/GraphQL error:/g, '')
    .replace(/\s{2,9}/g, ' ');
  return trim(errorString);
};

export default errorsToString;
