import { Box, Text, Button } from '@marketmuse/components';
import { InventoryLimitedProps } from '@marketmuse/config/types/inventory';
import { pluralize, format } from '@marketmuse/utilities';

function MessageInventoryLimited({
  counts,
  status,
  onCheckAllocation,
  onAddTopicAddon,
}: InventoryLimitedProps) {
  return (
    <>
      <Text as="p">
        You are trying to add <strong>{counts.adding}</strong>{' '}
        {pluralize('topic', counts.adding)}, and{' '}
        <strong>{counts.siteAvailable}</strong> can be added to this Inventory.
        There {counts.orgAvailable === 1 ? 'is' : 'are'}{' '}
        <strong>{format(counts.orgAvailable)}</strong> unallocated topics
        available.
      </Text>
      {status === 'site-topics-limited' && (
        <>
          <Text as="p" className={['mt-2']}>
            Please go to your settings page, and allocate more topics to this
            Inventory.
          </Text>
          <Box className={['gap-4', 'flex', 'mt-2']}>
            <Button
              onClick={() => onCheckAllocation()}
              variant="blue400"
              size="sm"
            >
              Go to settings page
            </Button>
          </Box>
        </>
      )}
      {status === 'organization-topics-limited' && (
        <>
          <Text as="p">
            Please check your allocations, or purchase more topics to continue.
          </Text>
          <Box className={['gap-4', 'flex', 'mt-2']}>
            <Button
              onClick={() => onCheckAllocation()}
              variant="blue200"
              size="sm"
            >
              Check allocations
            </Button>
            <Button
              onClick={() => onAddTopicAddon()}
              variant="blue400"
              size="sm"
            >
              Purchase topics
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

export default MessageInventoryLimited;
