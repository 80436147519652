import { Suspense, useRef, useState } from 'react';
import { RecurlyError, TokenPayload } from '@recurly/recurly-js';
import { lowerCase, isEmpty } from 'lodash';

import {
  Text,
  Modal,
  Box,
  Button,
  toast,
  LoadingSpinner,
} from '@marketmuse/components';
import { PAPI } from '@marketmuse/config/types';
import {
  useRecurlyUpsertBillingInfoMutation,
  useSubscriptionQuery,
} from '@marketmuse/data-papi';
import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state';
import {
  useStoreSelector,
  useStoreDispatch,
} from '@marketmuse/data-state/hooks';
import { miscActions } from '@marketmuse/data-state/misc';
import { paymentActions } from '@marketmuse/data-state/payment';
import { lazyLoad, format, titleCase } from '@marketmuse/utilities';

const BillingInfo = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'BillingInfo',  webpackMode: "eager", webpackPreload: 1 */ '@marketmuse/account-subscription/components/Billing/BillingFields'
    ),
);

async function recurlyTokenizeForm(form: HTMLFormElement) {
  return await new Promise<{
    token: TokenPayload | null;
    error: RecurlyError | null;
  }>(resolve => {
    window.recurly.token(form, (error, token) => {
      if (error) {
        resolve({ token: null, error });
      } else {
        resolve({ token, error: null });
      }
    });
  });
}

function useBillingUpdate() {
  const dispatch = useStoreDispatch();

  const [billingUpdate, { loading }] = useRecurlyUpsertBillingInfoMutation();

  const handler = async ({
    recurlyToken,
    accountCode,
  }: {
    recurlyToken: string;
    accountCode: string;
  }) => {
    // provide token to papi, papi provide token to recurly, recurly has credit info
    const billingUpdateRes = await billingUpdate({
      variables: {
        recurlyToken,
        accountCode,
      },
    });

    if (billingUpdateRes.data?.recurlyUpsertBillingInfo) {
      // Update billing information in state
      dispatch(
        paymentActions.setAccount(
          billingUpdateRes.data.recurlyUpsertBillingInfo,
        ),
      );
    }

    if (billingUpdateRes.errors) {
      toast.error('Error updating billing information');
      return false;
    }

    return true;
  };

  return [handler, { loading }] as const;
}

const Loader = () => (
  <Box className={['text-blue-400', 'text-center', 'self-center']}>
    <LoadingSpinner
      className={['p-8', 'h-auto', 'w-auto']}
      iconProps={{ boxSize: 32 }}
    />
  </Box>
);

const Dunning = () => {
  const dispatch = useStoreDispatch();
  const [poll, setPoll] = useState(false);
  const billingFormRef = useRef<HTMLFormElement | null>(null);
  const recurlyAccount = useStoreSelector(state => state?.payment?.account);
  const subscription = useStoreSelector(state => state?.payment?.subscription);
  const code = recurlyAccount?.code;
  const [handleBillingUpdate, { loading }] = useBillingUpdate();

  useSubscriptionQuery({
    skip: !poll || !subscription?.id,
    pollInterval: 5000,
    variables: {
      subscriptionId: subscription?.id || '',
    },
    onCompleted: data => {
      if (!poll) {
        return;
      }

      if (data?.subscription?.state === PAPI.SubscriptionState.PAID) {
        setPoll(false);

        // force sign in
        window.location.reload();
        return;
      }
    },
  });

  const onUpdateBilling = async () => {
    const { token, error: tokenError } = await recurlyTokenizeForm(
      billingFormRef.current,
    );

    if (tokenError) {
      toast.error(
        tokenError.message || 'Unable to tokenize billing information',
      );
      return;
    }

    const billingUpdate = await handleBillingUpdate({
      recurlyToken: token?.id || '',
      accountCode: code || '',
    });

    if (!billingUpdate) {
      return;
    }

    toast.success(
      'Billing information updated successfully. Access will be restored shortly.',
    );
    setPoll(true);
  };

  const price = subscription?.subscriptionPlan?.price || 0;

  const isLoaded = !isEmpty(recurlyAccount?.id);

  return (
    <Modal
      isVisible={true}
      onClose={() => {
        // do nothing
      }}
      disableClose={true}
      closeBtnClassName={['hidden']}
      panelClassName={['w-fit', 'max-w-fit', 'p-10']}
    >
      <Box>
        <Box className={['prose', 'mb-4']}>
          <Text as="h1" className={['text-2xl', 'mb-2']}>
            Billing Issue
          </Text>
          <Text as="p">
            Your payment has failed. Please update your payment method to
            continue using MarketMuse.
          </Text>
        </Box>

        <Box
          className={[
            'flex',
            'flex-row',
            'gap-4',
            'min-h-[350px]',
            isLoaded ? 'justify-normal' : 'justify-center',
          ]}
        >
          {!isLoaded && <Loader />}

          {!code && isLoaded === true && (
            <Text>There's no financial account linked to your account</Text>
          )}
          {code && isLoaded === true && (
            <>
              <Box className={['flex', 'flex-col', 'w-[320px]', 'gap-2']}>
                <Text className={['font-bold', 'text-base']}>
                  Your package:
                </Text>
                <Box className={['flex', 'gap-1', 'flex-wrap']}>
                  <Text>
                    {titleCase(subscription?.subscriptionPlan?.type || '')}
                  </Text>
                  <Text>
                    <strong>
                      {format(price / 100, {
                        type: 'money',
                      })}
                    </strong>
                    /{lowerCase(subscription?.subscriptionPlan?.term || '')}
                  </Text>
                  <Box
                    className={[
                      'w-full',
                      'basis-full',
                      'text-2xs',
                      'text-gray-600',
                    ]}
                  >
                    Not including any add-ons, or coupons that may be applied.
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="blue200"
                    onClick={() =>
                      dispatch(
                        miscActions.setSimple({
                          key: MISC_SIMPLE_KEYS.subscriptionManagement,
                          value: true,
                        }),
                      )
                    }
                  >
                    Change Package
                  </Button>
                </Box>
              </Box>
              <Box className={['w-[320px]']}>
                <Suspense fallback={<Loader />}>
                  <BillingInfo
                    ref={billingFormRef}
                    firstName={recurlyAccount?.billingInfo?.firstName || ''}
                    lastName={recurlyAccount?.billingInfo?.lastName || ''}
                  />
                  <Button
                    onClick={() => onUpdateBilling()}
                    variant="blue400"
                    disabled={loading}
                    className="mt-4"
                  >
                    {loading ? <LoadingSpinner /> : 'Update payment method'}
                  </Button>
                </Suspense>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
export default Dunning;
