import React, { FC } from 'react';
import { ITruncableElementProps } from '../types';

export const TruncableElement: FC<ITruncableElementProps> = ({
  prev,
  isNextTruncable,
  isPreviousTruncable,
  truncableClassName,
  truncableText,
}) => {
  const hasPrev = isPreviousTruncable && prev === true;
  const hasNextNoPrev = isNextTruncable && !prev;
  if (hasPrev || hasNextNoPrev) {
    return (
      <span className={truncableClassName || void 0}>{truncableText}</span>
    );
  }
  return null;
};
