// This file manages the some of the internal states and the business
// logic around the input component. This should be agnostic of any
// styling or other functional props (side items, labels etc). Please
// use Base files or individual style files for that purposes.

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { isNil, get } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components';
import simpleTextInputTypes from '../../config/propTypes/simpleTextInput';

import Box from '../Box';
import DefaultWrapper, {
  DefaultInput,
  DefaultTextarea,
} from './themes/Default';
import LegacyWrapper, { LegacyInput, LegacyTextarea } from './themes/Legacy';
import LightWrapper, { LightInput, LightTextarea } from './themes/Light';
import DarkWrapper, { DarkInput, DarkTextarea } from './themes/Dark';

// map themes to components
const wrappers = {
  dark: DarkWrapper,
  legacy: LegacyWrapper,
  light: LightWrapper,
};
const inputs = { dark: DarkInput, legacy: LegacyInput, light: LightInput };
const textareas = {
  dark: DarkTextarea,
  legacy: LegacyTextarea,
  light: LightTextarea,
};
const Note = styled.div`
  font-size: 12px;
  color: ${p => p.theme.colors.grey60};
`;
const SimpleTextInput = (propsArg = {}, ref) => {
  const inputRef = useRef(null);
  const [tmpState, setTmpState] = useState(get(propsArg, 'targetValue', ''));

  // do not pass these to the text input
  const {
    id,
    className,
    style,
    onEnter,
    onEsc,
    onBlur,
    onDebounce,
    onDebounceDynamic,
    onKeyDown,
    onChange,
    onPaste,
    debounceInterval,
    type,
    noAutoComplete,
    note,
    ...props
  } = propsArg;
  let UseWrapper = wrappers[props.inputTheme] || DefaultWrapper;
  let UseInput = inputs[props.inputTheme] || DefaultInput;
  let UseTextarea = textareas[props.inputTheme] || DefaultTextarea;

  const hasLabel = !isNil(props.label);

  const handleDebounce = useCallback(
    (value, name) => {
      if (typeof onDebounce === 'function') {
        onDebounce(value, name);
      }
      if (typeof onDebounceDynamic === 'function') {
        onDebounceDynamic(value, name);
      }
    },
    [onDebounce, onDebounceDynamic],
  );

  const debounceFn = useDebouncedCallback(
    handleDebounce,
    debounceInterval || 500,
  );

  const useClear = useCallback(() => {
    debounceFn('', props?.name);
    setTmpState();
  }, [debounceFn, setTmpState, props?.name]);

  // update value when target value changes
  useEffect(() => {
    if (props.targetValueWatch) setTmpState(props.targetValue);
  }, [props.targetValue, props.targetValueWatch]);

  // auto focus on mount
  useEffect(() => {
    if (props.autoFocus) {
      const activeRef = ref || inputRef;
      const activeEl = get(activeRef, 'current');
      if (!activeEl) return;
      activeEl.focus();
    }
  }, [props.autoFocusWatch, props.autoFocus]);

  let UseComponent = UseInput;
  if (props.textarea) UseComponent = UseTextarea;

  const autoCompleteProps = !noAutoComplete
    ? {}
    : {
        autoComplete: 'new-password',
        'data-lpignore': 'true',
      };

  return (
    <>
      <UseWrapper
        {...props}
        clear={useClear}
        style={style}
        type={type}
        className={className}
        isEmpty={!props.value && !tmpState}
        hasLabel={hasLabel}
        tabIndex="-1"
      >
        {({ useType } = {}) => (
          <UseComponent
            id={id}
            value={tmpState}
            type={useType || 'text'}
            ref={ref || inputRef}
            aria-label={hasLabel ? props.label : props.placeholder}
            onBlur={onBlur}
            onChange={e => {
              // call debounce function
              if (debounceFn) debounceFn(e.target.value, e.target.name);
              // call the default onChange function
              if (typeof onChange === 'function') {
                onChange(e);
              } else {
                setTmpState(e.target.value);
              }
            }}
            onKeyDown={e => {
              // esc key
              if (e && e.keyCode === 27) {
                if (typeof onEsc === 'function') {
                  onEsc(e);
                }
              }
              // enter key
              if (e && e.keyCode === 13) {
                if (typeof onEnter === 'function') {
                  onEnter(e);
                }
              }
              // run default onKeyDown
              if (typeof onKeyDown === 'function') {
                onKeyDown(e);
              }
            }}
            onPaste={onPaste}
            {...autoCompleteProps}
            {...props}
          />
        )}
      </UseWrapper>{' '}
      {note && (
        <Box boxVariant="flex" mt={8}>
          <Note>{note}</Note>
        </Box>
      )}
    </>
  );
};

const SimpleTextInputWrapped = React.forwardRef(SimpleTextInput);

SimpleTextInputWrapped.propTypes = simpleTextInputTypes;

SimpleTextInputWrapped.defaultProps = {
  textarea: false,
  className: '',
  inputTheme: 'default',
};

export default SimpleTextInputWrapped;
