import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get, isNil } from 'lodash';

import { regexCleanup } from '@marketmuse/utilities';
import Distribution from '../../../components/Distribution';
import { SLRow, SLItem } from '../Optimize/shared/List';
import optimizeResearchVariantsExpanded from '../../../models/tracker/events/optimize/OptimizeResearchVariantsExpanded';

const VariantTitle = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${p => p.theme.colors.grey50};
  font-size: 11px;
  font-weight: bold;
`;

const VariantValue = styled.div`
  font-weight: bolder;
  font-size: 32px;
  line-height: 32px;
  padding-bottom: 12px;
`;

const Row = ({
  allVariants,
  index,
  isVariantMode,
  preventVariantData,
  setVariant,
  topic,
  useMatches,
}) => {
  const hasVariants = (allVariants[topic.term] || []).length > 0;
  const termSafe = (topic.term || '').toLowerCase();

  const handleOnClick = () => {
    if (preventVariantData || isVariantMode || !hasVariants) {
      return;
    }

    setVariant(topic.term);
    optimizeResearchVariantsExpanded.record({
      expandedTopic: topic.term,
    });
  };

  return (
    <SLRow
      onClick={handleOnClick}
      data-mms--optimize={`research-topic-${index + 1}`}
    >
      <SLItem grow pl={0} style={{ whiteSpace: 'normal' }}>
        {topic.term}
      </SLItem>
      <SLItem width={80} p={0}>
        <Distribution value={get(useMatches, regexCleanup(termSafe)) || 0} />
      </SLItem>
      <SLItem width={90} p={0}>
        <Distribution value={topic.recommended_mentions} />
      </SLItem>
    </SLRow>
  );
};

const ToolkitTabResearch = ({
  preventVariantData,
  appData,
  decors,
  variant,
  setVariant,
  moveForwardTo,
}) => {
  const variantMatches = useMemo(
    () => get(decors, 'decorVariants') || {},
    [decors],
  );
  const terms = useMemo(() => get(appData, 'scores.kg.data') || [], [appData]);
  const allVariants = useMemo(
    () => get(appData, 'scores.all_variants') || {},
    [appData],
  );
  const variants = useMemo(
    () => get(allVariants, variant) || [],
    [appData, variant],
  );
  const matches = useMemo(
    () => get(decors, 'decorRelatedTopics') || {},
    [decors],
  );

  // for variant slide-in view, notify parent the
  // render is finished so it can slide this in
  useEffect(() => {
    if (variant) {
      setTimeout(() => {
        moveForwardTo('contents');
      }, 100);
    }
  }, []);

  const isVariantMode = !isNil(variant);
  const termsData = isVariantMode ? variants : terms;
  const matchesData = isVariantMode ? variantMatches : matches;

  return (
    <div data-mms--toolkit-tab={isVariantMode ? 'variant' : 'topic'}>
      {/* variant label */}
      {isVariantMode && (
        <>
          <VariantTitle>Related Topic</VariantTitle>
          <VariantValue>{variant}</VariantValue>
        </>
      )}

      {/* research table header */}
      <SLRow header>
        <SLItem header grow pl={0}>
          {isVariantMode ? 'Variant Topics' : 'Topic'}
        </SLItem>
        <SLItem header width={80} pl={0} pr={0} alignCenter>
          Mentions
        </SLItem>
        <SLItem header width={90} pl={0} pr={0} alignCenter>
          Suggested Mentions
        </SLItem>
      </SLRow>

      {/* no data
        {useTerms.length === 0 && (
          <SLRow>
            <SLItem grow pl={0}>No data</SLItem>
          </SLRow>
        )}
        */}

      {/* research table */}
      {termsData.map((topic, index) => {
        return (
          <Row
            key={`optimize-term-${topic.term.toString()}-${index}`}
            topic={topic}
            index={index}
            setVariant={setVariant}
            preventVariantData={preventVariantData}
            isVariantMode={isVariantMode}
            allVariants={allVariants}
            useMatches={matchesData}
          />
        );
      })}
    </div>
  );
};

ToolkitTabResearch.propTypes = {
  appData: PropTypes.object,
  decors: PropTypes.object,

  allowVariantUse: PropTypes.bool,

  // variant view
  variant: PropTypes.string,
  setVariant: PropTypes.func,
  moveForwardTo: PropTypes.func,
  moveBackTo: PropTypes.func,
};

export default ToolkitTabResearch;
