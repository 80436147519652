import React, { ComponentType } from 'react';
import { useSuiteNavigate } from '../hooks/useSuiteNavigate';
import { NavigateArgs } from '../hooks/useSuiteNavigate/types';

type NavigateProp = {
  navigate: (path: string, args?: NavigateArgs) => void;
};

export const withNavigate =
  <P extends object>(
    Component: ComponentType<React.PropsWithChildren<P & NavigateProp>>,
  ): React.FC<React.PropsWithChildren<P>> =>
  props => {
    const navigate = useSuiteNavigate();
    const modifiedProps = {
      navigate,
      ...props,
    };

    return <Component {...modifiedProps} />;
  };
