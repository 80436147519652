import { FC } from 'react';
import { IResearchDataProps } from '../types';
import ResearchActions from './ResearchActions';
import ResearchData from './ResearchData';
import ResearchPermissions from './ResearchPermissions';
import ResearchUiState from './ResearchUiState';
import ResearchViewModel from './ResearchViewModel';

const ResearchProvider: FC<IResearchDataProps> = ({
  children,
  renderMessageInventoryLimited,
}) => {
  return (
    <ResearchPermissions>
      <ResearchUiState>
        <ResearchViewModel>
          <ResearchData>
            <ResearchActions
              renderMessageInventoryLimited={renderMessageInventoryLimited}
            >
              {children}
            </ResearchActions>
          </ResearchData>
        </ResearchViewModel>
      </ResearchUiState>
    </ResearchPermissions>
  );
};

export default ResearchProvider;
