import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

import { Box } from '../Box';
import { SUPPORTED_ICON } from '../Icon';
import { LoadingBlock } from '../Loading';
import { Text } from '../Text';

import { NavigationButton } from './components/NavigationButton';
import { PageButton } from './components/PageButton';
import usePagination from './hooks/usePagination';
import { IPaginationProps } from './types';

const wrapperClasses = [
  'flex',
  'items-center',
  'justify-between',
  'gap-x-2',
  'w-full',
  'bg-white',
  'px-2',
  'sm:px-4',
  'lg:px-8',
  'py-2',
  'border-t',
  'border-gray-150',
];

export const Pagination = ({
  className,
  limit,
  loading,
  maxCount = 5000,
  onChangeLimit,
  onChangePage,
  onNext,
  onPrevious,
  page,
  resultCount,
  rowsControl,
  wrapperAttrs = {},
}: IPaginationProps) => {
  const pagination = usePagination({
    page: page - 1,
    limit,
    resultCount,
    maxCount,
  });
  const paginationWrapperClassName =
    typeof className === 'string' ? className : cx(className);
  return (
    <Box
      className={twMerge(wrapperClasses, paginationWrapperClassName)}
      {...wrapperAttrs}
    >
      <Box className={cx('flex text-xs font-bold text-gray-500 gap-x-1')}>
        {loading && <LoadingBlock style={{ width: '140px' }} />}
        {!loading && pagination.pages.length > 0 && (
          <Text> {`Results: ${pagination.itemsShowing}`}</Text>
        )}
      </Box>
      <Box className="flex items-center h-10 text-sm select-none">
        <Box className="flex items-center justify-center w-full gap-5">
          {loading && <LoadingBlock style={{ width: '320px' }} />}
          {!loading && pagination.pages.length > 0 && (
            <>
              <NavigationButton
                title={'Previous'}
                iconName={SUPPORTED_ICON.chevron_left}
                disabled={!pagination.hasPreviousPage}
                onClick={onPrevious}
                iconPosition="left"
              />
              <PageButton
                currentPage={pagination.page}
                previousPages={pagination.previousPages}
                middlePages={pagination.middlePages}
                nextPages={pagination.nextPages}
                setCurrentPage={onChangePage}
                truncableElementProps={{
                  isPreviousTruncable: pagination.isPreviousTruncable,
                  isNextTruncable: pagination.isNextTruncable,
                  truncableText: '...',
                  truncableClassName: 'text-center',
                }}
              />
              <NavigationButton
                title={'Next'}
                iconName={SUPPORTED_ICON.chevron_right}
                disabled={!pagination.hasNextPage}
                onClick={onNext}
                iconPosition="right"
              />
            </>
          )}
        </Box>
      </Box>

      {loading && <LoadingBlock className="w-[105px]" />}
      {!loading && (
        <Box className="flex gap-x-2 items-center justify-end text-gray-500">
          <Text className={'text-xs font-bold'}>Rows:</Text>

          <select
            value={limit}
            className={cx([
              'shadow-sm',
              'text-xs',
              'font-bold',
              'border-gray-150',
              'rounded-md',
              'min-w-fit',
              'pr-6',
            ])}
            onChange={event => {
              onChangeLimit(Number(event.target.value));
            }}
          >
            {rowsControl?.map(count => (
              <option key={`rows-${count}`} value={count}>
                {count}
              </option>
            ))}
          </select>
        </Box>
      )}
    </Box>
  );
};
