import { FC, memo, useCallback } from 'react';
import {
  Box,
  Button,
  Icon,
  SUPPORTED_ICON,
  Text,
} from '@marketmuse/components';
import { DomainProps } from '../../types';

const Domain: FC<DomainProps> = ({ domain, onRemove }) => {
  const handleRemove = useCallback(() => {
    onRemove?.(domain);
  }, [domain, onRemove]);

  return (
    <li className="flex items-center justify-between gap-2.5 px-4 py-2">
      <Box className="flex items-center justify-start gap-2.5">
        <Icon name={SUPPORTED_ICON.globe} />
        <Text className="text-sm text-gray-700">{domain}</Text>
      </Box>
      <Button
        variant="none"
        className="p-0 text-gray-500 hover:text-gray-500"
        onClick={handleRemove}
        aria-label="Remove domain"
      >
        <Icon name={SUPPORTED_ICON.close} />
      </Button>
    </li>
  );
};

export default memo(Domain);
