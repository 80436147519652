import * as Sentry from '@sentry/browser';
import { lazy, ComponentType } from 'react';

type LazyLoadComponent = {
  default: ComponentType<React.PropsWithChildren<any>>;
};

const loader = (lazyComponent: any, attemptsLeft = 3) => {
  return new Promise<LazyLoadComponent>((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            Sentry.captureMessage(
              `lazyLoad: Out of retry attempts - ${error?.message}`,
            );
            reject(error);
            return;
          } else {
            Sentry.captureException(error);
            loader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
          }
        }, 1000);
      });
  });
};

export const lazyLoad = (importFunction: any) =>
  lazy(() => loader(importFunction));
