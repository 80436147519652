import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import { isProd, isStaging } from '../../../utils/isEnv';

import Agent from '../Agent';

class SentryAgent extends Agent {
  canInit(user) {
    return super.canInit(user);
  }

  isReady() {
    return true;
  }

  track(data) {
    if (!isProd() && !isStaging()) {
      return;
    }

    Sentry.getCurrentScope().addBreadcrumb({
      message: get(data, 'newName'),
      ...data,
    });
  }

  init(user) {
    if (!this.canInit(user, this)) {
      return;
    }

    Sentry.getCurrentScope().setUser({
      id: user?.id,
      email: user?.email,
      orgId: user?.org?.id,
      orgName: user?.org?.name,
      tier: user?.tier,
    });
  }
  update(user) {
    if (!this.canInit(user, this)) {
      return;
    }
    Sentry.getCurrentScope().setUser({
      id: user?.id,
      email: user?.email,
      orgId: user?.org?.id,
      orgName: user?.org?.name,
      tier: user?.tier,
    });
  }

  initAnonymous() {
    return;
  }

  clear() {
    Sentry.getCurrentScope().setUser({});
  }
}
export default SentryAgent;
