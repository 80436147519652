import React, { CSSProperties, ComponentType } from 'react';
import { Polymorphic } from '@marketmuse/components';

const withTailwindCompatibility =
  <P extends object>({
    Component,
    style = {},
    as = 'div',
  }: {
    Component: ComponentType<React.PropsWithChildren<P>>;
    style: CSSProperties;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: React.ElementType<any>;
  }): React.FC<React.PropsWithChildren<P>> =>
  props => {
    return (
      <Polymorphic
        as={as}
        className="mm-tw"
        style={{ width: '100%', ...style }}
      >
        <Component {...props} />
      </Polymorphic>
    );
  };

export default withTailwindCompatibility;
