/**
 * Super class extended by tracking agents. Use this as an interface to
 * enforce agents to implement necessary classes, and for some common
 * functionality.
 */

import { get, isNil, isEmpty, trim } from 'lodash';

class Agent {
  // user related functionality
  isUserAvailable(user) {
    return !isNil(get(user, 'id')) && !isNil(get(user, 'email'));
  }

  // user related functionality
  serializeAddress = args => {
    if (isEmpty(args)) {
      return void 0;
    }

    const {
      city,
      country,
      firstName,
      lastName,
      postalCode,
      region,
      street1,
      street2,
    } = args;
    const address = [
      [firstName, lastName].join(' '),
      street1,
      street2,
      city,
      region,
      postalCode,
      country,
    ]
      .filter(val => !isEmpty(trim(val)))
      .join(', ');

    return address ? address : void 0;
  };

  // agent related functionality
  canInit(user, child) {
    if (!this.isUserAvailable(user)) {
      return false;
    }
    return true;
  }

  missingImplementation(method) {
    throw new Error(
      `Missing implementation for '${method}' method ` +
        'in one of the track agent classes.',
    );
  }

  // methods that must be implemented by agent classes
  init() {
    this.missingImplementation('init');
  }
  track() {
    this.missingImplementation('track');
  }
  clear() {
    this.missingImplementation('clear');
  }
  isReady() {
    this.missingImplementation('isReady');
  }
  page() {
    // optional
  }
  update() {
    // optional
  }
}

export default Agent;
