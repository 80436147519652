import * as types from '../config/types';

export const getRecurlyAccount = ({
  callback,
  accountCode,
  accountCodeTest,
} = {}) => ({
  type: types.RECURLY_GET_ACCOUNT,
  callback,
  accountCode,
  accountCodeTest,
});

export const upsertBillingInfo = ({
  recurlyToken,
  callback,
  callbackFail,
}) => ({
  type: types.RECURLY_UPSERT_BILLING_INFO,
  recurlyToken,
  callback,
  callbackFail,
});

export const recurlyCreateAccount = ({ test, params, callback }) => ({
  type: types.RECURLY_CREATE_ACCOUNT,
  test,
  params,
  callback,
});
