import { useCallback } from 'react';
import { get, isEmpty } from 'lodash';

import { TOKEN_KEYS } from '@marketmuse/config/configs';
import {
  useGhostAsUserLazyQuery,
  useRecurlyHydrateStateLazyQuery,
} from '@marketmuse/data-papi';
import {
  useStoreSelector,
  useStoreDispatch,
} from '@marketmuse/data-state/hooks';
import { errorsToString, checkIsSuperAdmin } from '@marketmuse/utilities';
import { paymentActions } from '@marketmuse/data-state/payment';

import useSiteChange from '../useSiteChange';
import { useSuiteNavigate } from '../useSuiteNavigate';
import getSignInPathDefault from '../../utils/getSignInPathDefault';

import { setUserAndSite } from '../../actions/authenticateActions';
import { adminModeEnable } from '../../actions/adminActions';
import { selectSite } from '../../actions/siteActions';

const useGhostMode = () => {
  const dispatch = useStoreDispatch();
  const driver = useStoreSelector(state => state.user);
  const admin = useStoreSelector(state => state.admin);
  const navigate = useSuiteNavigate();

  const setSite = useSiteChange({
    resetApps: true,
    skipFetchPermissions: false,
    slug: void 0,
    siteId: void 0,
  });
  const [recurlyHydrateState] = useRecurlyHydrateStateLazyQuery();
  const [queryFn] = useGhostAsUserLazyQuery({
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const enableGhostMode = useCallback(
    ({ userId, userEmail, siteId, orgId }) => {
      const isAdmin = checkIsSuperAdmin({
        userOrgId: driver?.org?.id,
      });
      if (!isAdmin) {
        alert('You must be a super admin to use ghost mode.');
        return;
      }

      const userSiteHash = btoa([userId, userEmail, siteId].join('|#|'));
      sessionStorage.setItem(TOKEN_KEYS.GHOST, userSiteHash);

      queryFn({
        variables: {
          id: userId,
          orgId,
        },
      })
        .then(res => {
          const { data, error } = res;
          if (isEmpty(data) || error) {
            const message = error
              ? errorsToString([error])
              : `Unable to log in as ${userEmail}`;
            throw new Error(message);
          }

          dispatch(adminModeEnable({ user: driver }));

          const user = get(data, 'user') || {};
          const org = get(data, 'user.org');
          const orgSites = get(data, 'user.org.sites');
          const subscription = get(data, 'user.org.subscription');

          dispatch(paymentActions.setSubscription(subscription));

          let orgSite = orgSites.find(site => site.id === siteId);
          if (!orgSite) {
            orgSite = orgSites[0];
          }
          const path = getSignInPathDefault();
          recurlyHydrateState({
            fetchPolicy: 'no-cache',
            variables: {
              accountCode:
                process.env['REACT_APP_RECURLY_MODE'] !== 'production'
                  ? get(org, 'recurlyAccountCodeTest')
                  : get(org, 'recurlyAccountCode'),
            },
          }).then(({ data }) => {
            dispatch(paymentActions.setAccount(data.recurlyGetAccount));
          });

          dispatch(
            setUserAndSite({
              site: orgSite,
              user: {
                ...user,
                org,
              },
            }),
          );

          setSite({
            siteId: orgSite?.id,
            slug: org?.slug,
            path,
          });
        })
        .catch(error => {
          alert(error.message);
        });
    },
    [dispatch, driver, queryFn, setSite, recurlyHydrateState],
  );

  const disableGhostMode = useCallback(() => {
    const site = get(admin, 'org.sites[0]');

    dispatch(setUserAndSite({ user: admin, site }));
    dispatch(selectSite({ id: site.id, resetApps: true }));
    navigate('admin/orgs', { omitOrgSlug: true });
    sessionStorage.removeItem(TOKEN_KEYS.GHOST);
  }, [navigate, dispatch, admin]);

  return {
    enableGhostMode,
    disableGhostMode,
  };
};

export default useGhostMode;
