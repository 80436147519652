import { isEqual } from 'lodash';
import { InputPillListActions, InputPillListState } from './types';

type SetItems = {
  type: InputPillListActions.SET_ITEMS;
  payload: {
    items: InputPillListState['items'];
  };
};

type SetData = {
  type: InputPillListActions.SET_DATA;
  payload: Partial<InputPillListState>;
};

type OnKeyPress = {
  type: InputPillListActions.ON_KEY_PRESS;
  payload: {
    isKeyReleased: boolean;
  };
};

export const reducer = (
  state: InputPillListState,
  action: SetData | SetItems | OnKeyPress,
) => {
  switch (action.type) {
    case InputPillListActions.SET_DATA: {
      const next = {
        ...state,
        ...action.payload,
      };

      return next;
    }

    case InputPillListActions.ON_KEY_PRESS:
      return { ...state, isKeyReleased: action.payload.isKeyReleased };

    case InputPillListActions.SET_ITEMS: {
      if (!isEqual(state.items, action.payload.items)) {
        const nextItems = action.payload.items;

        return {
          ...state,
          items: nextItems,
        };
      }

      return state;
    }
    default:
      throw new Error();
  }
};
