import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import Backdrop from './components/Backdrop';
import CloseButton from './components/CloseButton';
import useModalContentClasses from './hooks/useModalContentClasses';
import useModalContentTransition from './hooks/useModalContentTransition';
import { ModalPropsInterface, MODAL_MODE } from './types';

const Modal: ModalPropsInterface = ({
  children,
  className,
  closeBtnClassName,
  disableClose,
  isVisible,
  mode,
  onAfterLeave,
  onClose,
  onOutsideClick,
  panelClassName,
  panelWrapperClassName,
  unmount,
}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const { wrapper, panel } = useModalContentClasses(MODAL_MODE[mode]);
  const transitionProps = useModalContentTransition(MODAL_MODE[mode]);
  const slideout = useMemo(
    () =>
      [MODAL_MODE.slideoutRight, MODAL_MODE.slideoutLeft].includes(
        MODAL_MODE[mode],
      ),
    [mode],
  );
  const onClickOutside = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [onClose],
  );

  const closeButtonProps = {
    mode,
    onClick: onClose,
    className: closeBtnClassName,
    disableClose,
  };

  return (
    <Transition afterLeave={onAfterLeave} appear as={Fragment} show={isVisible}>
      <Dialog
        open={isVisible}
        as="div"
        className={twMerge(['relative z-10', className])}
        onClose={() => void 0}
        unmount={unmount}
      >
        <Backdrop />
        {slideout && <CloseButton {...closeButtonProps} />}
        <Transition.Child as={Fragment} {...transitionProps}>
          <div
            className={twMerge(wrapper, panelWrapperClassName)}
            onClick={onClickOutside}
          >
            <Dialog.Panel
              ref={panelRef}
              className={twMerge(panel, panelClassName)}
            >
              {!slideout && <CloseButton {...closeButtonProps} />}
              {children}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Modal;

Modal.Title = Dialog.Title;
Modal.Description = Dialog.Description;
