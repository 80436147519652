import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Box, Button, Input } from '@marketmuse/components';
import { isDomain } from '@marketmuse/utilities';
import { DomainAdderProps } from '../types';

const DomainAdder: FC<DomainAdderProps> = ({ onAdd, placeholder }) => {
  const [value, setValue] = useState('');

  useEffect(() => () => setValue(''), []);

  const isButtonDisabled = useMemo(() => !isDomain(value), [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  const handleAdd = useCallback(() => {
    onAdd?.(value);
    setValue('');
  }, [onAdd, value]);

  return (
    <Box className="flex align-middle justify-between gap-4 mb-2.5">
      <Input
        name="domain"
        label="Domain"
        labelProps={{ className: 'sr-only' }}
        inputProps={{
          type: 'text',
          className: ['text-sm', 'border-gray-150'],
          value,
          onChange: handleChange,
          placeholder,
        }}
      />
      <Button
        variant="gray150"
        className="text-sm"
        onClick={handleAdd}
        disabled={isButtonDisabled}
      >
        Add
      </Button>
    </Box>
  );
};

export default memo(DomainAdder);
