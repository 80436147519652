import * as Sentry from '@sentry/browser';

import {
  Language,
  PermissionResource,
  PermissionAction,
} from '@marketmuse/config/types/papi';
import { ACTION_TYPES } from '@marketmuse/data-state';
import { RESEARCH_ACTIONS } from '@marketmuse/data-state/appResearch';
import { FILTER_ACTIONS } from '@marketmuse/data-state/filter';
import {
  getCountryEnum,
  asyncDelay,
  validatePermission,
} from '@marketmuse/utilities';
import { OPTIMIZE_ACTIONS } from '@marketmuse/data-state/appOptimize';
import { loadingsActions } from '@marketmuse/data-state/loadings';
import {
  savedViewsActionTypes,
  savedViewsListSet,
} from '@marketmuse/data-state/savedViews';

import { getSavedViewsEach } from '../../actions/savedViewActions';
import { getAggregateStats } from '../../actions/siteActions';
import { setSimple } from '../../actions/miscActions';
import { setActiveInventory, selectSite } from '../../actions/siteActions';
import { fetchPermissions } from '../../utils/fetchPermissions';

const createSavedViewsActionArgs = ({ siteId, userId }) => ({
  siteId,
  each: ['PAGE_TOPICS', 'PAGES', 'TOPICS'],
  filters: [
    {
      OR: [
        {
          sharing: 'PUBLIC',
        },
        {
          sharing: 'SHARED',
        },
        {
          sharing: 'PRIVATE',
          createdBy: [{ id: userId }],
        },
      ],
    },
  ],
});

// main
export default () =>
  ({ dispatch, getState }) =>
  next =>
  async action => {
    const state = getState();

    // Site changed
    if (action.type === ACTION_TYPES.ACTIVE_INVENTORY_SELECT) {
      const sites = state?.user?.org?.sites || [];
      let permissions = state.permissions || [];
      const site = sites.find(({ id }) => id === action.id) || {};
      const { id: siteId, invId } = site;

      if (!siteId) {
        /**
         * There seems to be a race condition where user is set,
         * then site selected, but site not in state.
         * */
        return asyncDelay(500).then(() => {
          Sentry.captureMessage('Site not found in state', {
            level: 'warning',
          });
          dispatch(selectSite(action));
        });
      }

      // update site object state (state.filter.site, user.sites[id])
      dispatch(setActiveInventory(site));

      if (!action.skipFetchPermissions) {
        permissions = await fetchPermissions(dispatch, siteId);
      }

      dispatch(
        setSimple({
          key: `userSite_${state?.user?.id}`,
          value: siteId,
          persist: true,
        }),
      );

      // - get saved views
      dispatch(
        loadingsActions.setSimple({
          key: savedViewsActionTypes.SAVED_VIEWS_HYDRATE,
          value: true,
        }),
      );
      dispatch(
        getSavedViewsEach({
          ...createSavedViewsActionArgs({
            siteId,
            userId: state?.user?.id,
          }),
          limit: 2,
          callback: data => {
            if (data && Array.isArray(data)) {
              data.forEach(({ inventoryType, items }) => {
                dispatch(
                  savedViewsListSet({ inventoryType, items: items || [] }),
                );
              });
            }

            setTimeout(() => {
              dispatch(
                loadingsActions.setSimple({
                  key: savedViewsActionTypes.SAVED_VIEWS_HYDRATE,
                  value: false,
                }),
              );
            }, 250);
          },
        }),
      );

      // aggregated counts
      // has access check there - need to have all data to be in state
      dispatch(getAggregateStats({ siteId }));

      if (action.resetApps) {
        dispatch(
          OPTIMIZE_ACTIONS.optimizeTaskSiteChange({
            country: getCountryEnum(site.defaultSerpCountry || 'us'),
            siteId: siteId,
          }),
        );
      } else {
        dispatch(
          OPTIMIZE_ACTIONS.optimizeTaskHydrateCheck({
            country: getCountryEnum(site.defaultSerpCountry || 'us'),
            siteId: siteId,
          }),
        );
      }

      dispatch(
        RESEARCH_ACTIONS.researchTaskSiteChange({
          siteId: siteId,
          competitorDomains: site.competitorDomains || [],
          networkDomains: site.networkDomains || [],
          url: '',
          term: '',
          language: Language.EN,
          country: getCountryEnum(site.defaultSerpCountry || 'us'),
        }),
      );

      const canGetVersions = validatePermission({
        permissions,
        permission: {
          resource: PermissionResource.SITE_INVENTORY,
          action: PermissionAction.ACCESS_DATA,
        },
      });
      if (invId && canGetVersions) {
        dispatch(
          FILTER_ACTIONS.inventoryVersionsCheck({
            siteId,
          }),
        );
      } else {
        dispatch({
          type: ACTION_TYPES.INVENTORY_VERSIONS_SAVE,
          payload: [],
        });
      }
    }

    return next(action);
  };
