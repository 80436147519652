import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Account as RecurlyAccount } from '@marketmuse/config/types/papi';
import * as ACTION_TYPES from '../actionTypes';
import { SlicePayment } from '../types';

export const initialState: SlicePayment = {
  account: {},
  subscription: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<RecurlyAccount>) => {
      state.account = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    removeAddon: (state, action: PayloadAction<{ id: string }>) => {
      state.subscription.addons = state.subscription.addons.filter(
        addon => addon.id !== action.payload.id,
      );
    },
    removeAddonSite: (state, action: PayloadAction<{ siteId: string }>) => {
      state.subscription.addons = state.subscription.addons.map(addon => {
        if (addon.site?.id !== action.payload.siteId) {
          return addon;
        }

        return {
          ...addon,
          site: null,
        };
      });
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      action => action.type === ACTION_TYPES.SIGN_OUT,
      () => initialState,
    );
  },
});

export const paymentActions = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
