import { useState, useEffect, forwardRef, KeyboardEvent } from 'react';
import cx from 'classnames';

import { RECURLY } from '@marketmuse/config/types';
import {
  recurlyFieldsConfig,
  recurlyBillingCountries,
} from '@marketmuse/config/configs/recurly';

import { Text, Box, Input } from '@marketmuse/components';
import { Address, BillingInfo } from '@marketmuse/config/types/papi';

type Props = {
  firstName: string;
  lastName: string;
  billingInfo: BillingInfo;
};

type FormState = {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  isoCountry: string;
};

const countries: RECURLY.BillingCountries = recurlyBillingCountries;

const labelClassNames = [
  'block',
  'text-xs',
  'font-bold',
  'text-gray-700',
  'block',
  'mb-1',
];

const selectClassNames = cx([
  'shadow-sm',
  'focus:ring-blue-400',
  'focus:border-blue-400',
  'block',
  'w-full',
  'sm:text-sm',
  'border-gray-400',
  'rounded',
]);

const BillingFields = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const address = props.billingInfo?.address || ({} as Address);

  const [formState, setFormState] = useState<FormState>({
    firstName: props.firstName || '',
    lastName: props.lastName || '',
    address1: address.street1 || '',
    address2: address.street2 || '',
    city: address.city || '',
    state: address.region || '',
    postalCode: address.postalCode || '',
    isoCountry: address.country?.toLocaleLowerCase() || 'us',
  });

  const handleInputChange = (event: KeyboardEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFormState(last => ({ ...last, [name]: value }));
  };

  // recurly injects frames on `recurly.configure`, so it needs
  // to be reconfigured every time the form is mounted
  useEffect(() => {
    window.recurly?.configure({
      publicKey:
        process.env.REACT_APP_RECURLY_PUBLIC_KEY ||
        process.env.STORYBOOK_RECURLY_PUBLIC_KEY,
      fields: recurlyFieldsConfig,
    });
  }, []);

  return (
    <form ref={ref} className={cx(['flex', 'flex-col', 'gap-2'])}>
      <Box>
        <Input
          label="First Name"
          name="firstName"
          labelProps={{ className: labelClassNames }}
          inputProps={{
            name: 'firstName',
            'data-recurly': 'first_name',
            value: formState.firstName,
            onChange: handleInputChange,
          }}
        />
      </Box>
      <Box>
        <Input
          label="Last Name"
          name="lastName"
          labelProps={{ className: labelClassNames }}
          inputProps={{
            name: 'lastName',
            'data-recurly': 'last_name',
            value: formState.lastName,
            onChange: handleInputChange,
          }}
        />
      </Box>

      <Box>
        <label>
          <Text className={labelClassNames}>
            Card Number (no dashes or spaces)
          </Text>

          <div
            data-recurly="card"
            className={cx([
              '*:text-sm',
              '*:!border-gray-400',
              '*:!mt-0',
              '*:!h-[38px]',
              '*:!rounded',
              '*:!shadow-sm',
            ])}
          />
        </label>
      </Box>

      <Box>
        <Input
          label="Address"
          name="address1"
          labelProps={{ className: labelClassNames }}
          inputProps={{
            name: 'address1',
            'data-recurly': 'address1',
            value: formState.address1,
            onChange: handleInputChange,
          }}
        />
      </Box>
      <Box>
        <Input
          label="Apartment, Suite, etc. (optional)"
          name="address2"
          labelProps={{ className: labelClassNames }}
          inputProps={{
            name: 'address2',
            'data-recurly': 'address2',
            value: formState.address2,
            onChange: handleInputChange,
          }}
        />
      </Box>

      <Box className={['grid', 'grid-cols-2', 'gap-4']}>
        <Box>
          <Input
            label="City"
            name="city"
            labelProps={{ className: labelClassNames }}
            inputProps={{
              name: 'city',
              'data-recurly': 'city',
              value: formState.city,
              onChange: handleInputChange,
            }}
          />
        </Box>
        <Box>
          <Input
            label="State"
            name="state"
            labelProps={{ className: labelClassNames }}
            inputProps={{
              name: 'state',
              'data-recurly': 'state',
              value: formState.state,
              onChange: handleInputChange,
            }}
          />
        </Box>
      </Box>

      <Box>
        <Input
          label="Zip Code"
          name="postalCode"
          labelProps={{ className: labelClassNames }}
          inputProps={{
            name: 'postalCode',
            'data-recurly': 'postal_code',
            value: formState.postalCode,
            onChange: handleInputChange,
          }}
        />
      </Box>

      <Box>
        <label>
          <Text className={labelClassNames}>Country</Text>
          <select
            className={selectClassNames}
            onChange={event => {
              const [option] = Array.from(event.target.options)
                .filter(option => option.selected)
                .map(option => option.value);
              setFormState({ ...formState, isoCountry: option });
            }}
            value={formState.isoCountry}
            data-recurly="country"
          >
            {Object.values(countries).map(country => (
              <option key={country.id} value={country.id}>
                {country.title}
              </option>
            ))}
          </select>
        </label>
      </Box>
    </form>
  );
});

export default BillingFields;
