import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { REACT_APP } from '../config/env';
import {
  ignoreErrors,
  denyUrls,
  beforeSend,
  beforeBreadcrumb,
} from '../config/sentryConfig';

export default () => {
  const options = {
    environment: REACT_APP.SENTRY_ENVIRONMENT || 'local',
    dsn: REACT_APP.SENTRY_DSN,
    // don't create new release for local
    ...(!process.env.REACT_APP_DEPLOY_PREVIEW && {
      release: [REACT_APP.SENTRY_ENVIRONMENT, process.env.REACT_APP_COMMIT_REF]
        .filter(Boolean)
        .join('-'),
    }),
    replaysSessionSampleRate:
      REACT_APP.SENTRY_ENVIRONMENT === 'production' ? 1.0 : 0.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.extraErrorDataIntegration({ depth: 5 }),
    ],
    normalizeDepth: 7,
    tracesSampleRate: parseInt(REACT_APP.SENTRY_SAMPLING || '0', 10),
    beforeSend,
    beforeBreadcrumb,
    ignoreErrors: ignoreErrors,
    denyUrls: denyUrls,
  };

  if (!process.env.REACT_APP_DEPLOY_PREVIEW) {
    Sentry.init(options);
  }
};
