import { isNil, get } from 'lodash';
import { store } from '../../../config/instances';
import { REACT_APP } from '../../../config/env';

// Things to record:
// - action (this should be passed over by each event)
// - user id
// - name
// - email
// - window.location.href
// - timestamp
// - user role
// - org (id, slug)
// - active inventory (id, url)
// - is mm admin account (connected to the user)

export const getDefaults = () => {
  const state = store.getState();

  // is MM admin connecting to another account
  const isConnectedMMAdmin =
    !isNil(get(state, 'admin.id')) &&
    !isNil(get(state, 'user.id')) &&
    get(state, 'admin.org.id') === REACT_APP.MM_ORG_ID &&
    get(state, 'user.org.id') !== REACT_APP.MM_ORG_ID;

  return {
    org: get(state, 'user.org.id'),
    orgSlug: get(state, 'user.org.slug'),
    orgSFDCAccountID: get(state, 'user.org.sfdcAccountId'),
    orgRecurlyAccountCode: get(state, 'user.org.recurlyAccountCode'),
    userId: get(state, 'user.id'),
    userName: get(state, 'user.fullName'),
    userEmail: get(state, 'user.email'),
    userOrgRole: get(state, 'user.orgRole'),
    defaultApplicationSerpCountry: get(
      state,
      'user.defaultApplicationSerpCountry',
    ),
    displayExactDistribution: get(state, 'user.displayExactDistribution'),
    activeInventoryId: get(state, 'filter.site.id'),
    activeInventoryUrl: get(state, 'filter.site.domain'),
    activeInventoryLevel: '',
    activeInventorySERPCountry: get(state, 'filter.site.defaultSerpCountry'),
    location: window.location.href,
    date: Date(),
    dateUnix: Date.now(),
    mmAdmin: isConnectedMMAdmin,
    mmAdminUserId: isConnectedMMAdmin ? get(state, 'admin.id', null) : '',
    mmAdminName: isConnectedMMAdmin ? get(state, 'admin.fullName', null) : '',
    mmAdminEmail: isConnectedMMAdmin ? get(state, 'admin.email', null) : '',
  };
};
