type TopicAddRes = {
  status: 'organization-topics-limited' | 'site-topics-limited' | 'allowed';
  counts: {
    siteAvailable: number;
    orgAvailable: number;
  };
};

function checkInventoryTopicAddition({
  countAdding,
  orgTopicsAllocated,
  orgTopicsMax,
  siteTopicsAllocated,
  siteTopicsCurrent,
}: {
  countAdding: number;
  orgTopicsAllocated: number;
  orgTopicsMax: number;
  siteTopicsAllocated: number;
  siteTopicsCurrent: number;
}): TopicAddRes {
  const siteTopicsAvailable = Math.max(
    siteTopicsAllocated - siteTopicsCurrent,
    0,
  );
  const orgTopicsAvailable = Math.max(orgTopicsMax - orgTopicsAllocated, 0);
  const exceedsSiteLimit = countAdding > siteTopicsAvailable;

  const counts = {
    siteAvailable: siteTopicsAvailable,
    orgAvailable: orgTopicsAvailable,
  };

  if (countAdding > orgTopicsAvailable && exceedsSiteLimit) {
    // There are not enough unallocated topics within the org
    return {
      status: 'organization-topics-limited',
      counts,
    };
  }

  if (exceedsSiteLimit) {
    // there are not enough unallocated topics within the current site
    return {
      status: 'site-topics-limited',
      counts,
    };
  }

  return {
    status: 'allowed',
    counts,
  };
}

export default checkInventoryTopicAddition;
