import React from 'react';
import { serializeToAppData } from '@marketmuse/utilities';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withAppDataSerializer = (Component: React.ComponentType<any>) => {
  return ({ data, ...rest }) => {
    const modifiedProps = {
      appData: serializeToAppData({
        bulkAdwordsKeywords: data.bulkAdwordsKeywords,
        knowledgeGraphItems: data.knowledgeGraph?.knowledgeGraphItems || [],
        metrics: data.knowledgeGraph?.metrics || {},
        organicSerpItemPagesKgData:
          data.knowledgeGraph?.organicSerpItemPagesKgData || [],
        userProvidedPage: data.knowledgeGraph?.userProvidedPage || {},
      }),
      ...rest,
    };

    return <Component {...modifiedProps} />;
  };
};
