import { useEffect, useMemo } from 'react';
import { get } from 'lodash';

import { useUser } from '@clerk/clerk-react';
import { tracker } from '../../models/tracker/utils/initTracker';
import { lazyLoadReplay } from '../../utils/lazyLoadReplay';
import { initTrackedUser, trackerReset } from '../../utils/tracker';
import useSessionData from './useSessionData';
import { IdentityContext } from './context';

const IdentityProvider = ({ children }) => {
  const sessionId = useSessionData();

  const { isLoaded, isSignedIn, user } = useUser();

  useEffect(() => {
    if (isLoaded && isSignedIn && user) {
      const email = user.emailAddresses?.[0].emailAddress;
      // init tracker if user email not already in tracker
      if (get(tracker, 'user.email') !== email) {
        initTrackedUser({
          user: {
            id: user.id,
            fullName: user.fullName,
            firstName: user.firstName,
            lastName: user.lastName,
            email,
          },
          site: null,
          subscriptions: null,
          recurlyAccount: null,
        });
      }

      lazyLoadReplay();
    }

    if (!user) {
      trackerReset();
    }
  }, [user, isSignedIn, isLoaded]);

  const context = useMemo(
    () => ({
      sessionId,
      isLoading: isLoaded === null,
      isSignedIn,
      user,
    }),
    [sessionId, isLoaded, isSignedIn, user],
  );

  return (
    <IdentityContext.Provider value={context}>
      {children}
    </IdentityContext.Provider>
  );
};

export default IdentityProvider;
