import { Tracker } from '../Tracker';

export const initTracker = () => {
  // usage for non browser environments
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (Tracker?.default) {
    return new Tracker();
  }

  // browser safe usage
  if (Tracker?.prototype) {
    return new Tracker();
  }

  return null;
};

export const tracker = initTracker();
