import React from 'react';
import { ResearchProvider } from '@marketmuse/research-data';
import MessageInventoryLimited from '@marketmuse/inventory-ui/MessageInventoryLimited';
import Research from './Research';

const Wrapper = () => {
  return (
    <ResearchProvider renderMessageInventoryLimited={MessageInventoryLimited}>
      <Research />
    </ResearchProvider>
  );
};

export default Wrapper;
