import React, { useEffect, createContext } from 'react';
import { useLocation } from 'react-router-dom';

import { tracker } from '../models/tracker/utils/initTracker';
import Tracker from '../models/tracker/Tracker';

const TrackingContext = createContext<Tracker>(null);

const TrackingProvider = ({ children }) => {
  const location = useLocation();
  tracker.initAnonymous();

  useEffect(() => {
    tracker.page({ pathname: location.pathname });
  }, [location?.pathname]);

  return (
    <TrackingContext.Provider value={tracker}>
      {children}
    </TrackingContext.Provider>
  );
};

export default TrackingProvider;
